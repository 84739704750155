import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { Document, Page } from 'react-pdf';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ContextMenu from "../../components/contextMenu/contextMenu";
import { ButtonBase, Tooltip } from '@mui/material';
import './pdfReader.scss';
import { addToPlaylist } from '../../service/requests';
import CircularProgress from '@mui/material/CircularProgress';
import { pdfjs } from 'react-pdf';
import { openNotification } from "../../actions/notification";
import { requestCommonData } from './../../actions/commonData';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const mapStateToProps = (state => ({
  ...state.commonData,
  ...state.currentUser
}))

const mapDispatchToProps = (dispatch) => ({
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  }
});

const PdfReader = ({ pdfUrl, etudeName, folders, currentEtude, user, openNotification }) => {
  const dispatch = useDispatch();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const foldersWithPlaylists = folders.filter(folder => folder.playlists.length > 0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const goToNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const downloadPdf = () => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.target = '_blank';
    link.setAttribute('download', 'document.pdf');
    document.body.appendChild(link);
    link.click();
  };


  const handleClick = (event) => {
    setAnchorEl(
      {
        top: event.clientY + 30,
        left: event.clientX - 200,
      }
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddToPlaylist = async (playliastId) => {
    try {
      const res = addToPlaylist({
        etude_id: currentEtude.etude_id,
        playlist_id: playliastId,
        rating: currentEtude.rating,
        tag_id: currentEtude.tag_id,
        user_id: user.id
      }).then(() => {
        dispatch(requestCommonData());
      })
      openNotification({ text: 'Added to playlist', notificationType: "success" });
    } catch (e) {
      console.log('e', e)
      openNotification({ text: 'Something went wrong"', notificationType: "error" });
    }
  }


  const options = foldersWithPlaylists.map(folder => {
    const playlists = folder.playlists;
    return {
      label: folder.folder_name,
      subitems: playlists.map(playlist => {
        return {
          label: playlist.playlist_name,
          action: () => handleAddToPlaylist(playlist.playlist_id)
        };
      })
    };
  });


  return (
    <div className="pdf-viewer">
      <div className='search__title flex flex-col lg:flex-row justify-between items-center'>
        <div className='w-full lg:w-fit'>{etudeName}</div>
        <div className="flex  w-full lg:w-fit justify-between items-center text-lg mt-3 lg:mt-0">
          <div className='flex items-center'>
            <p>
              Page: {pageNumber}/{numPages}
            </p>
            <div className='mx-2 mb-1'>
              <button onClick={goToPreviousPage} disabled={pageNumber === 1}>
                <ArrowBackIosNewRoundedIcon s />
              </button>
              <button onClick={goToNextPage} disabled={pageNumber === numPages}>
                <ArrowForwardIosRoundedIcon />
              </button>
            </div>
          </div>
          <div className='flex items-center'>
            <Tooltip title="Download" placement='bottom'>
              <img src="/images/ico/pdf.svg" onClick={downloadPdf} className='w-5' alt="personal upload" />
            </Tooltip>
            <Tooltip title="Add to playlist" placement='bottom'>
              <AddCircleRoundedIcon className='ml-2 cursor-pointer' onClick={handleClick} />
            </Tooltip>
          </div>

          {open && (
            <ContextMenu
              anchorPosition={{ top: anchorEl.top, left: anchorEl.left }}
              options={options}
              handleClose={handleClose}
              index={open?.index}
            />
          )}

        </div>
      </div>

      <div className="pdf-container">
          <Document
            file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          // renderMode="svg"
          loading={<div className='mx-auto mt-20 w-fit'><CircularProgress size={50} sx={{ color: '#2f5473' }} /></div>}
        >
          <Page scale='3' pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
        </Document>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PdfReader);
