import {
    CHECK_LAST_ELEM,
    SET_SEARCH_WORD,
    SET_PAGE,
    FILTER_TAGS,
    SELECT_COMPOSER_OPUS,
    CLEAR_COMPOSER_OPUS,
    CLEAR_ALL_FILTERS,
    HOVER_COMPOSER_OPUS
} from '../actions';

const search = ( state = {
    searchWord: null,
    page: 0,
    selectedTag: false,
    filteredTags: [],
    selectedOpus: null,
    hoveredOpus: null
 },
  action) => {
 
     const {
        searchWord,
        page,
        selectedTag,
        filteredTags,
        selectedOpus,
        hoveredOpus
     } = action;
 
     switch (action.type) {
         case SET_SEARCH_WORD: {
            return {
                ...state,
                searchWord,
            }
        }
 
         case SET_PAGE: {
            return {
                ...state,
                page: page,
            }
        }

        case SELECT_COMPOSER_OPUS: {
            return {
                ...state,
                selectedOpus,
                hoveredOpus: null,
                selectedTag: null
            }
        }

        case HOVER_COMPOSER_OPUS: {
            return {
                ...state,
                hoveredOpus: hoveredOpus,
                selectedTag: null
            }
        }

        case CLEAR_COMPOSER_OPUS: {
            return {
                ...state,
                selectedOpus
            }
        }

        case CLEAR_ALL_FILTERS: {
            return {
                ...state,
                selectedOpus,
                filteredTags
            }
        }

        case FILTER_TAGS: {
            return {
                ...state,
                filteredTags
            }
        }
 
 
         case CHECK_LAST_ELEM:
             {
                 return {
                     ...state,
                     selectedTag,
                     hoveredOpus: null
                 }
             }
 
         default:
             return state;
     }
 }

export default search;