import { combineReducers } from 'redux';
import popups              from './popup';
import currentUser         from './currentUser';
import search              from './search';
import notifications       from './notification';
import folders             from './folders';
import tags                from './tags';
import commonData          from './commonData';
import library             from './library';

const rootReducer = combineReducers({
    popups,
    currentUser,
    search,
    notifications,
    folders,
    tags,
    commonData,
    library
});


export default (state, action) =>
  rootReducer(action.type === 'USER_LOGOUT' ? undefined : state, action);