import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import FilteredMenu from '../../components/filteredMenu/filteredMenu';
import PdfReader from './../etudesPage/pdfReader';
import defaultPdf from '../../weather.pdf';
import { useParams } from 'react-router-dom';
import { baseURL } from '../../service/requests';


const mapStateToProps = (state => ({
    ...state.commonData
}))

const FilteredPage = (props) => {
    const { etudes } = props;
    const { composerId, etudeId, opus, tagIds = '' } = useParams();
    const [currentEtude, setCurrentEtude] = useState();

    useEffect(() => {
        if (etudes) {
            const foundEtude = etudes.find(etude => etude.etude_id == etudeId);
            setCurrentEtude(foundEtude);
        }
    }, [etudeId, etudes]);

    const tags = tagIds.split(':');

    return (
        <div className='flex flex-col sm:flex-row'>
            <FilteredMenu currentEtude={currentEtude} composerId={composerId} opus={opus} tagIds={tags} />
            <div className='content flex-1 py-1 sm:py-10 px-5 sm:px-8'>
                <div className='search__content'>
                    {currentEtude &&
                        <PdfReader pdfUrl={`${baseURL}/content/etude-sheetmusic/${currentEtude.etude_sheetmusic}.pdf`} etudeName={currentEtude.etude_name} currentEtude={currentEtude} />
                    }
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(FilteredPage);
