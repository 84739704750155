import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import { SaveButton, CancelButton } from '../../buttons/buttons';
import { closePopup } from "../../../actions/popup";
import { openNotification } from "../../../actions/notification";
import CircularProgress from '@mui/material/CircularProgress';
import { renamePlaylist, renameFolder } from "../../../service/requests";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { requestCommonData } from "../../../actions/commonData";
import { withTranslation } from 'react-i18next';

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => {
    return dispatch(closePopup());
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
});

const Rename = (props) => {
  const { closePopup, openNotification, info, t } = props;
  const type = info.type;
  const [data, setData] = useState(info.data);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onPlaylistRename = async () => {
    if (data) {
      setIsLoading(true)
      try {
        await renamePlaylist({ playlist_name: data, id: info.playlistId })
        dispatch(requestCommonData());
        openNotification({ text: 'Updated!', notificationType: "success" });
        setIsLoading(false);
        closePopup();
      } catch (e) {
        console.log(e)
        setIsLoading(false);
      }
    }
  }

  const onFoldertRename = async () => {
    if (data) {
      setIsLoading(true)
      try {
        const res = await renameFolder({ folder_id: info.folderId, folder_name: data })
        dispatch(requestCommonData());
        console.log('res', res)
        setIsLoading(false);
        closePopup();
      } catch (e) {
        console.log(e)
        setIsLoading(false);
      }
    }
  }


  const onSave = (event) => {
    event.preventDefault();
    if (info.type === 'playlist') {
      onPlaylistRename()
    }
    if (info.type === 'folder') {
      onFoldertRename()
    }
  }

  return (
    <div className='mt-7 w-full sm:w-96 relative'>
      <form onSubmit={onSave}>
      {type === 'comment'
        ? <TextField
          multiline
          rows={5}
          size='small'
          value={data}
          onChange={(e) => setData(e.target.value)}
          label={`${type.charAt(0).toUpperCase() + type.slice(1)}`}
          placeholder={`Enter ${type}`}
          className='w-full'
          variant="outlined" />
        : <TextField
          autoFocus
          size='small'
          value={data}
          onChange={(e) => setData(e.target.value)}
          label={`${type.charAt(0).toUpperCase() + type.slice(1)}`}
          placeholder={`Enter ${type}`}
          className='w-full'
          variant="outlined" />
      }
      <div className='mt-7 ml-auto w-min flex gap-3'>
        <SaveButton disabled={isLoading} text='OK' onClick={onSave} />
        <CancelButton text={t("library.actions.cancel")} onClick={() => closePopup()} />
      </div>
      {isLoading &&
        <div className='popupLoaderWrapper'><CircularProgress size={50} sx={{ color: '#2f5473' }} /></div>
      }
      </form>
    </div>
  )
}

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(Rename));
