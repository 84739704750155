import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider } from '@mui/material/styles';
import theme from './utils/customTheme'; // Import your custom theme
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
const root = ReactDOM.createRoot(document.getElementById('root'));
const clientId = "189062098162-eg3puap37n3m434cghfbmj3rf8lodv30.apps.googleusercontent.com";

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <GoogleOAuthProvider clientId={clientId}>
          <ThemeProvider theme={theme}>
            <I18nextProvider i18n={i18n}>
              <App />
            </I18nextProvider>
          </ThemeProvider>
        </GoogleOAuthProvider>
      </Router>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
