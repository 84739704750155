import React, { useState } from 'react';
import { connect, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import { SaveButton, CancelButton } from '../../buttons/buttons';
import { closePopup } from "../../../actions/popup";
import { openNotification } from "../../../actions/notification";
import { createFolder } from "../../../service/requests";
import { requestCommonData } from "../../../actions/commonData";
import { withTranslation } from 'react-i18next';


const mapDispatchToProps = (dispatch) => ({
  closePopup: () => {
    return dispatch(closePopup());
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
});

const CreateFolder = (props) => {
  const dispatch = useDispatch();
  const { closePopup, openNotification, t } = props;
  const [folderName, setFolderName] = useState('');
  const [folderError, setFolderError] = useState(false);

  const onCreateFolder = async (event) => {
    event.preventDefault();

    if(folderName) {
      try {
        await createFolder({folder_name: folderName })
        dispatch(requestCommonData());
        openNotification({ text: 'The folder has been created', notificationType: "success" });
        closePopup();
      } catch (e) {
        console.log(e)
      }
    } else {
      setFolderError(true)
    }
  }

  return (
    <div className='mt-7 w-full sm:w-96'>
      <form onSubmit={onCreateFolder}>
      <TextField error={folderError} size='small' label={t("library.label.folderName")} placeholder={t("library.placeholder.enterFolderName")} className='w-full' value={folderName} onChange={(e) => {setFolderName(e.target.value); setFolderError(false)}} variant="outlined" />
      <div className='mt-7 ml-auto w-min flex gap-3'>
        <SaveButton text={t("library.actions.save")} onClick={onCreateFolder} />
        <CancelButton text={t("library.actions.cancel")} onClick={() => closePopup()} />
        <button hidden='true' type="submit"></button>
      </div>
      </form>
    </div>
  )
}

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(CreateFolder));