import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ButtonBase from '@mui/material/ButtonBase';
import { openPopup } from '../../actions/popup';
import { withTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import i18n from '../../i18n';
import './homePage.scss';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TextField from '@mui/material/TextField';
import { openNotification } from '../../actions/notification';
import validator from 'validator';
import { contact } from '../../service/requests';

const mapDispatchToProps = dispatch => ({
    openPopup: (payload) => {
        return dispatch(openPopup(payload));
    },
    openNotification: (payload) => {
        return dispatch(openNotification(payload));
    }
})


const HomePage = (props) => {
    const { t, isAuthenticated, openPopup, openNotification } = props;
    const [scrolled, setScrolled] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState('transparent');
    const [language, setLanguage] = React.useState('en');
    const [isOpen, setIsOpen] = useState(false);

    const [userData, setUserData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [error, setError] = useState({
        name: "",
        email: "",
        message: "",
    });

    const handleChange = (e) => {
        setUserData({
            ...userData,
            [e.target.id]: e.target.value,
        });

        setError({
            ...error,
            [e.target.id]: false,
        });
    }
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value);
        setLanguage(event.target.value);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 100) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToSection = (id, offset = 0) => {
        const element = document.getElementById(id);
        if (element) {
            const rect = element.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const targetScrollTop = rect.top + scrollTop - offset;

            window.scroll({
                top: targetScrollTop,
                behavior: 'smooth',
            });
        }
    };

    const logOut = () => {
        localStorage.removeItem("accessToken");
        window.location.reload();
    }

        // validation
        const validate = () => {
            let nameError = "";
            let emailError = "";
            let messageError = "";
    
            if (!userData.name || !userData.name.match(/[a-zA-Z]+/g)) {
                nameError = "Name can not be blank";
            }
    
            if (!validator.isEmail(userData.email)) {
                emailError = "Invalid email";
            }
    
            if (!userData.message) {
                messageError = "Message can not be blank";
            }
    
            if (emailError || nameError || messageError) {
                setError({
                    name: nameError,
                    email: emailError,
                    message: messageError,
                });
                return false;
            }
            return true;
        };

    const onContact = async () => {
        const isValid = validate();
        if (isValid) {
            try {
                const res = await contact(userData);
                // if ok 200
                // if(res.data) {
                // openNotification({text: 'Your message was sent', notificationType: "success"});
                // setUserData({name: '', email: '', message: ''})
                // }
            } catch (e) {
                openNotification({ text: 'Your message was sent', notificationType: "success" });
            }
        }
    }

    return (
        <div className='home text-darkBrown'>
            <div className='home__banner'>
                <div className={scrolled || isOpen ? 'homeMenu--active' : 'homeMenu'}>
                    <div className='contentWrapper flex flex-row sm:flex-col md:flex-row justify-between items-center'>
                        <div className='w-16 sm:w-20 flex-none'>
                            <object type="image/svg+xml" className='w-full' data="./images/logo.svg" />
                        </div>

                        <div className={`block sm:hidden homeMenu__burgerIcon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                            <div className="bar1"></div>
                            <div className="bar2"></div>
                            <div className="bar3"></div>
                        </div>

                        <div className={`homeMenu__mobileMenu ${isOpen ? 'open' : ''}`}>
                            <div className='flex sm:hidden flex-col gap-5 md:gap-7 flex-wrap '>
                                <a className='linkBtn' href='#'>{t("homePage.menuItem.about")}</a>
                                <a className='linkBtn' href='#'>{t("homePage.menuItem.features")}</a>
                                <a className='linkBtn' href='#'>{t("homePage.menuItem.contact")}</a>
                                <a className='linkBtn--accent' href='#'>{t("homePage.menuItem.arcoPro")}</a>
                                <a className='linkBtn--accent' href='#'>{t("homePage.menuItem.arcoTv")}</a>
                                <Select
                                    size='small'
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={language}
                                    onChange={changeLanguage}
                                >
                                    <MenuItem className='flex' value={'en'}><img className='w-5 mr-2' src="./images/flags/united-kingdom.svg" alt="english" />English</MenuItem>
                                    <MenuItem className='flex' value={'ua'}><img className='w-5 mr-2' src="./images/flags/ukraine.svg" alt="english" />Ukrainian</MenuItem>
                                </Select>


                                {isAuthenticated
                                    ?
                                    <div className='flex items-center gap-3'>
                                        <ButtonBase>
                                            <Link to='/library'
                                                className='bg-blue text-white rounded p-3 w-fit cursor-pointer transition hover:bg-hoverBlue font-bold'>
                                                {t("homePage.menuItem.library")}
                                            </Link>
                                        </ButtonBase>
                                        <ButtonBase>
                                            <Link to='/search'>
                                                <div
                                                    className='bg-blue text-white rounded p-3 w-fit cursor-pointer transition hover:bg-hoverBlue font-bold'>
                                                    {t("homePage.menuItem.search")}
                                                </div>
                                            </Link>
                                        </ButtonBase>
                                        <ButtonBase>
                                            <div
                                                onClick={logOut}
                                                className='bg-white border bodrer-blue text-blue rounded p-3 w-fit cursor-pointer transition hover:bg-transparentBlue font-bold'>
                                               {t("logout")}
                                            </div>
                                        </ButtonBase>
                                    </div>
                                    :
                                    <ButtonBase>
                                        <div
                                            onClick={() => openPopup({ currentPopup: 'Login', info: { title: 'Login' } })}
                                            className='bg-blue text-white rounded p-3 w-fit cursor-pointer transition hover:bg-hoverBlue font-bold'>
                                            Sign Up | Login
                                        </div>
                                    </ButtonBase>
                                }

                            </div>
                        </div>



                        <div className='hidden sm:flex items-center justify-center md:justify-end gap-5 md:gap-7 flex-wrap '>
                            <a className='linkBtn' href='#'>{t("homePage.menuItem.about")}</a>
                            <a className='linkBtn' href='#'>{t("homePage.menuItem.features")}</a>
                            <a className='linkBtn' href='#'>{t("homePage.menuItem.contact")}</a>
                            <a className='linkBtn--accent' href='#'>{t("homePage.menuItem.arcoPro")}</a>
                            <a className='linkBtn--accent' href='#'>{t("homePage.menuItem.arcoTv")}</a>
                            <Select
                                size='small'
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={language}
                                onChange={changeLanguage}
                            >
                                <MenuItem className='flex' value={'en'}><img className='w-5 mr-2' src="./images/flags/united-kingdom.svg" alt="english" />English</MenuItem>
                                <MenuItem className='flex' value={'ua'}><img className='w-5 mr-2' src="./images/flags/ukraine.svg" alt="english" />Ukrainian</MenuItem>
                            </Select>


                            {isAuthenticated
                                ?
                                <div className='flex items-center gap-3'>
                                    <ButtonBase>
                                        <Link to='/library'
                                            className='bg-blue text-white rounded p-3 w-fit cursor-pointer transition hover:bg-hoverBlue font-bold'>
                                            {t("homePage.menuItem.library")}
                                        </Link>
                                    </ButtonBase>
                                    <ButtonBase>
                                        <Link to='/search'>
                                            <div
                                                className='bg-blue text-white rounded p-3 w-fit cursor-pointer transition hover:bg-hoverBlue font-bold'>
                                               {t("homePage.menuItem.search")}
                                            </div>
                                        </Link>
                                    </ButtonBase>
                                    <ButtonBase>
                                        <div
                                            onClick={logOut}
                                            className='bg-white border bodrer-blue text-blue rounded p-3 w-fit cursor-pointer transition hover:bg-transparentBlue font-bold'>
                                           {t("logout")}
                                        </div>
                                    </ButtonBase>
                                </div>
                                :
                                <ButtonBase>
                                    <div
                                        onClick={() => openPopup({ currentPopup: 'Login', info: { title: 'Login' } })}
                                        className='bg-blue text-white rounded p-3 w-fit cursor-pointer transition hover:bg-hoverBlue font-bold'>
                                        {t("homePage.menuItem.signUpLogIn")}
                                    </div>
                                </ButtonBase>
                            }

                        </div>

                    </div>
                </div>

                <div className='flex flex-col items-center justify-center'>
                    <div className='text-center font-medium text-2xl md:text-4xl w-11/12 sm:w-9/12 lg:w-5/12 mx-auto mt-0 sm:mt-28 md:mt-10'>
                        <span className='font-bold'>{t("homePage.titleBold")}</span>{t("homePage.title")}
                    </div>
                    <ButtonBase className='home__bannerBtn mt-7 sm:mt-14 rounded' onClick={() => scrollToSection('aboutUs', 40)}>
                        <div
                            className='bg-white border bodrer-blue text-blue rounded py-3 px-10 w-fit cursor-pointer transition hover:bg-transparentBlue font-medium sm:font-bold uppercase'>
                            {t("homePage.learnMoreBtn")}
                        </div>
                    </ButtonBase>
                    <div className='w-11/12 lg:w-8/12 xl:w-5/12 my-7 sm:my-14'>
                        <div>
                            <iframe className='home__videoWrapper'
                                // height="400"
                                src={`https://www.youtube.com/embed/DQDItXHW840`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <section className='w-11/12 md:w-8/12 lg:w-5/12 mx-auto py-10 sm:py-20 text-darkBrown' id="aboutUs">
                <h2 className='font-medium text-3xl md:text-5xl text-center text-brown'>{t("homePage.problemsSection.title")}</h2>
                <div className='flex items-start mt-10'>
                    <div className='w-4 h-4 sm:h-5 sm:w-5 bg-blue rounded-full flex-none mr-5 mt-1 bg-brown'></div>
                    <div className='text-base sm:text-2xl font-normal'><span className='font-bold'>{t("homePage.problemsSection.textFirstAccent")}</span> {t("homePage.problemsSection.textFirst")}</div>
                </div>
                <div className='flex items-start mt-5'>
                    <div className='w-4 h-4 sm:h-5 sm:w-5 bg-blue rounded-full flex-none mr-5 mt-1 bg-brown'></div>
                    <div className='text-base sm:text-2xl font-normal'><span className='font-bold'>{t("homePage.problemsSection.textSecondAccent")}</span> {t("homePage.problemsSection.textSecond")}</div>
                </div>
                <div className='flex items-start mt-5'>
                    <div className='w-4 h-4 sm:h-5 sm:w-5 bg-blue rounded-full flex-none mr-5 mt-1 bg-brown'></div>
                    <div className='text-base sm:text-2xl font-normal'><span className='font-bold'>{t("homePage.problemsSection.textThirdAccent")}</span> {t("homePage.problemsSection.textThird")}</div>
                </div>
            </section>

            <section className='home__quote'>
                <div className='home__quoteOverlay'></div>
                <div className='w-11/12 md:w-8/12 lg:w-5/12 mx-auto py-10 sm:py-20 z-1 relative'>
                    <h2 className="font-bold text-lg sm:text-2xl">"{t("homePage.violin.title")}"</h2>
                    <p className='text-base sm:text-lg mt-5'>{t("homePage.violin.text")}</p>
                    <p className='text-base sm:text-lg mt-5'>{t("homePage.violin.name")}</p>
                    <p className='text-base sm:text-lg'>{t("homePage.violin.position")}</p>
                </div>
            </section>

            <section className='w-11/12 md:w-8/12 lg:w-5/12 mx-auto py-20 text-darkBrown'>
                <h2 className='font-medium text-3xl md:text-5xl text-center text-brown'>{t("homePage.problems.title")}</h2>
                <div className='flex items-start mt-10'>
                    <div className='h-5 w-5 bg-blue rounded-full flex-none mr-5 mt-1 bg-brown'></div>
                    <div className='text-base sm:text-lg font-medium'><span className='font-bold'>{t("homePage.problems.textFirstAccent")}</span> {t("homePage.problems.textFirst")}</div>
                </div>
                <div className='flex items-start mt-5'>
                    <div className='h-5 w-5 bg-blue rounded-full flex-none mr-5 mt-1 bg-brown'></div>
                    <div className='text-base sm:text-lg font-medium'><span className='font-bold'>{t("homePage.problems.textSecondAccent")}</span> {t("homePage.problems.textSecond")}</div>
                </div>
                <div className='flex items-start mt-5'>
                    <div className='h-5 w-5 bg-blue rounded-full flex-none mr-5 mt-1 bg-brown'></div>
                    <div className='text-base sm:text-lg font-medium'><span className='font-bold'>{t("homePage.problems.textThirdAccent")}</span> {t("homePage.problems.textThird")}</div>
                </div>

                <div className='flex items-start mt-5'>
                    <div className='h-5 w-5 bg-blue rounded-full flex-none mr-5 mt-1 bg-brown'></div>
                    <div className='text-base sm:text-lg font-medium'>{t("homePage.problems.textFourth")}</div>
                </div>
                <div className='flex items-start mt-5'>
                    <div className='h-5 w-5 bg-blue rounded-full flex-none mr-5 mt-1 bg-brown'></div>
                    <div className='text-base sm:text-lg font-medium'><span className='font-bold'>{t("homePage.problems.textFifthAccent")}</span> {t("homePage.problems.textFifth")}</div>
                </div>
            </section>

            <section className='bg-featuresBg py-10 sm:py-14'>
                <div className='w-11/12 xl:w-8/12 mx-auto'>
                    <h2 className='font-medium text-3xl md:text-5xl text-center text-title'>{t("homePage.features.title")}</h2>

                    <div className='flex flex-col sm:flex-row mt-7 sm:mt-14 items-center'>
                        <img className='w-full sm:w-6/12 border' src="https://images.pexels.com/photos/7853221/pexels-photo-7853221.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
                        <div className='w-full sm:w-6/12 ml-0 sm:ml-10 lg:ml-20 mt-3 sm:mt-0'>
                            <h3 className='font-bold text-xl sm:text-2xl text-left sm:text-center text-title'>{t("homePage.features.info.0.title")}</h3>
                            <p className='mt-3 sm:mt-5 font-medium text-base sm:text-lg'>{t("homePage.features.info.0.text")}</p>
                        </div>
                    </div>

                    <div className='flex flex-col-reverse sm:flex-row mt-7 sm:mt-14 items-center'>
                        <div className='w-full sm:w-6/12 mr-0 sm:mr-10 lg:mr-20 mt-3 sm:mt-0'>
                            <h3 className='font-bold text-xl sm:text-2xl text-left sm:text-center text-title'>{t("homePage.features.info.1.title")}</h3>
                            <p className='mt-3 sm:mt-5 font-medium text-base sm:text-lg'>{t("homePage.features.info.1.text")}</p>
                        </div>
                        <img className='w-full sm:w-6/12' src="https://images.pexels.com/photos/7853221/pexels-photo-7853221.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
                    </div>

                    <div className='flex flex-col sm:flex-row mt-7 sm:mt-14 items-center'>
                        <img className='w-full sm:w-6/12' src="https://images.pexels.com/photos/7853221/pexels-photo-7853221.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />

                        <div className='w-full sm:w-6/12 ml-0 sm:ml-10 lg:ml-20 mt-3 sm:mt-0'>
                            <h3 className='font-bold text-xl sm:text-2xl text-left sm:text-center text-title'>{t("homePage.features.info.2.title")}</h3>
                            <p className='mt-3 sm:mt-5 font-medium text-base sm:text-lg'>{t("homePage.features.info.2.items.0.title")}</p>
                            <ul>
                                <li>- {t("homePage.features.info.2.items.0.subItems.0")}</li>
                                <li>- {t("homePage.features.info.2.items.0.subItems.1")}</li>
                                <li>- {t("homePage.features.info.2.items.0.subItems.2")}</li>
                                <li>- {t("homePage.features.info.2.items.0.subItems.3")}</li>
                            </ul>
                            <p>{t("homePage.features.info.2.items.1.title")}</p>
                            <ul>
                                <li>-{t("homePage.features.info.2.items.1.subItems.0")}</li>
                                <li>- {t("homePage.features.info.2.items.1.subItems.1")}</li>
                                <li>- {t("homePage.features.info.2.items.1.subItems.2")}</li>
                            </ul>
                        </div>
                    </div>

                    <div className='flex flex-col-reverse sm:flex-row mt-7 sm:mt-14 items-center'>
                        <div className='w-full sm:w-6/12 mr-0 sm:mr-10 lg:mr-20 mt-3 sm:mt-0'>
                            <h3 className='font-bold text-xl sm:text-2xl text-left sm:text-center text-title'>{t("homePage.features.info.3.title")}</h3>
                            <div className='mt-3 sm:mt-5 font-medium text-base sm:text-lg'>
                                <p>{t("homePage.features.info.3.paragraphs.0")}</p>
                                <p className='mt-4'>{t("homePage.features.info.3.paragraphs.1")}</p>
                                <p className='mt-4'>{t("homePage.features.info.3.paragraphs.2")}</p>
                                <p className='font-bold mt-5'>{t("homePage.features.info.3.examples")}</p>
                                <ul className='list-disc ml-4 mt-2'>
                                    <li>{t("homePage.features.info.3.examplesItems.0")}</li>
                                    <li>{t("homePage.features.info.3.examplesItems.1")}</li>
                                    <li>{t("homePage.features.info.3.examplesItems.2")}</li>
                                    <li>{t("homePage.features.info.3.examplesItems.3")}</li>
                                    <li>{t("homePage.features.info.3.examplesItems.4")}</li>
                                    <li>{t("homePage.features.info.3.examplesItems.5")}</li>
                                    <li>{t("homePage.features.info.3.examplesItems.6")}</li>
                                    <li>{t("homePage.features.info.3.examplesItems.7")}</li>
                                    <li>{t("homePage.features.info.3.examplesItems.8")}</li>
                                    <li>{t("homePage.features.info.3.examplesItems.9")}</li>
                                    <li>{t("homePage.features.info.3.examplesItems.10")}</li>
                                </ul>
                            </div>
                        </div>
                        <img className='w-full sm:w-6/12' src="https://images.pexels.com/photos/7853221/pexels-photo-7853221.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
                    </div>
                </div>
            </section>

            <section className='home__arcoPro py-10 md:py-20'>
                <div className='home__arcoProOverlay'></div>
                <div className='w-11/12 xl:w-8/12 mx-auto relative'>
                    <p className='text-2xl md:text-4xl font-bold'>{t("homePage.arcoPro.title")} <span className='italic'>{t("homePage.commingSoon")}</span></p>
                    <ul className='list-disc ml-4 mt-5 text-xl md:text-2xl w-11/12 md:w-6/12'>
                        <li>{t("homePage.arcoPro.text.0")}</li>
                        <li className='mt-5'>{t("homePage.arcoPro.text.1")}</li>
                    </ul>
                </div>
            </section>

            <section className='home__arcoTv py-10 md:py-32'>
                <div className='home__arcoTvOverlay'></div>
                <div className='w-11/12 xl:w-8/12 mx-auto relative'>
                    <p className='text-2xl md:text-4xl font-bold'>{t("homePage.arcoTv.title")} <span className='italic'>{t("homePage.commingSoon")}</span></p>
                    <p className=' text-xl md:text-2xl mt-5 w-11/12 md:w-full'>{t("homePage.arcoTv.text")}</p>
                </div>
            </section>

            <section className='home__followUs py-10 sm:py-16'>
                <div className='w-fit mx-auto'>
                    <p className=' text-center text-xl md:text-3xl'>{t("homePage.followUs")}</p>
                    <div className='flex gap-7 mt-5'>
                        <a href='https://twitter.com/' target='_blank' className='hover:opacity-80 transition'>
                            <TwitterIcon fontSize="large" />
                        </a>
                        <a href='https://www.facebook.com/' target='_blank' className='hover:opacity-80 transition'>
                            <FacebookIcon fontSize="large" />
                        </a>
                        <a href='https://www.instagram.com/' target='_blank' className='hover:opacity-80 transition'>
                            <InstagramIcon fontSize="large" />
                        </a>
                        <a href='https://www.youtube.com/' target='_blank' className='hover:opacity-80 transition'>
                            <YouTubeIcon fontSize="large" />
                        </a>
                    </div>
                </div>
            </section>

            <section className='w-11/12 md:w-8/12 lg:w-5/12 mx-auto container py-10 sm:py-20'>
                <p className='text-center text-3xl text-brown'>{t("homePage.contact.title")}</p>
                <p className='text-center text-2xl text-gray mt-5'>{t("homePage.contact.text")}</p>
                <div className='mt-5'>
                    <div className='flex flex-col sm:flex-row mt-4 gap-5'>
                        <TextField error={error.name ? true : false} id="name" value={userData.name} onChange={(e) => { handleChange(e) }} size='large' label={t("name")} placeholder='Enter name' className='w-full' variant="outlined" />
                        <TextField error={error.email ? true : false} id="email" value={userData.email} onChange={(e) => { handleChange(e) }} size='large' label={t("email")} placeholder='Enter email' className='w-full' variant="outlined" />
                    </div>
                    <TextField
                        multiline
                        id="message"
                        rows={5}
                        error={error.message? true : false}
                        size='large'
                        label={t("message")}
                        value={userData.message} 
                        onChange={(e) => { handleChange(e) }}
                        placeholder='Enter your message'
                        className='w-full mt-5'
                        variant="outlined" />

                    <ButtonBase onClick={onContact} className='home__contactUsBtn uppercase block bg-blue text-white rounded py-3 px-10 w-full sm:w-fit cursor-pointer transition hover:bg-hoverBlue font-semibold text-lg mx-auto mt-5'>
                        {t("sendForm")}
                    </ButtonBase>
                </div>

            </section>



        </div>
    )
}

export default connect(
    null,
    mapDispatchToProps
)(withTranslation()(HomePage));