import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { SaveButton, CancelButton } from '../../buttons/buttons';
import { closePopup, openPopup } from "../../../actions/popup";
import { openNotification } from "../../../actions/notification";
import { changePassword } from '../../../service/requests';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { withTranslation } from 'react-i18next';

const PasswordConfirmation = (props) => {
    const { closePopup, openNotification, openPopup, info, t } = props;
    const [passwordError, setPasswordError] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const [user, setUser] = useState({
        password: "",
    });


    const handleChange = (e) => {
        setUser({
            ...user,
            [e.target.id]: e.target.value,
        });

        setPasswordError(false)
    }

    // validation
    const validate = () => {
        let passwordError = "";

        if (user.password.length < 5) {
            passwordError = "Password is too short";
        }
        if (user.password != passwordRepeat) {
            passwordError = "Passwords do not match";
        }

        if (passwordError) {
            setPasswordError(passwordError)
           
            return false;
        }
        return true;
    };


    const onResetPassword = async () => {
        const isValid = validate();

        if (isValid) {
            try {
                await changePassword({code: info.code, newPass: user.password});
                openNotification({ text: "Password has been changed", notificationType: "success" });
                closePopup();
                openPopup({ currentPopup: 'Login', info: { title: t("login") } })
            } catch(e) {
                console.log(e)
            }
        }
    }

    return (
        <div className='mt-7 w-full sm:w-96'>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" className='w-full m-0 mt-3'>
                <InputLabel htmlFor="outlined-adornment-password">{t("password")}</InputLabel>
                <OutlinedInput
                    id="password"
                    value={user.password}
                    // helperText={error.password}
                    onChange={(e) => { handleChange(e) }}
                    error={passwordError}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label={t("password")}
                />
            </FormControl>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" className='w-full m-0 mt-3'>
                <InputLabel htmlFor="outlined-adornment-password">{t("confirmPassword")}</InputLabel>
                <OutlinedInput
                    error={passwordError}
                    value={passwordRepeat}
                    onChange={(e) => { setPasswordRepeat(e.target.value); setPasswordError(false) }}
                    type={showPassword ? 'text' : 'password'}
                    onKeyDown={(event) => event.key === 'Enter' && onResetPassword(event)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label={t("confirmPassword")}
                />
                {passwordError && (
                    <FormHelperText error id="error_password" sx={{ margin: 0 }}>
                        {passwordError}
                    </FormHelperText>
                )}
            </FormControl>
            <div className='mt-7 ml-auto w-min flex gap-3'>
                <SaveButton text='OK' disabled={!user.password} onClick={onResetPassword} />
                <CancelButton text={t("library.actions.cancel")} onClick={() => closePopup()} />
            </div>
        </div>
    )
}

export default connect(
    null,
    { closePopup, openNotification, openPopup }
  )(withTranslation()(PasswordConfirmation));
  