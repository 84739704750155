import React, { useState, useEffect, useRef } from 'react';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import './contextMenu.scss';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

export default function ContextMenu(props) {
  const { options, handleClose, anchorPosition } = props;
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
  const [activeOption, setActiveOption] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);


  const handleSubmenuOpen = (event, option) => {
    setSubmenuAnchorEl(event.currentTarget);
    setActiveOption(option);
  };

  const handleSubmenuClose = () => {
    setSubmenuAnchorEl(null);
  };


  return (
    <ClickAwayListener
    onClickAway={() => {
      console.log("i got called");
      handleClose();
    }}>
   
      <Menu
        id="long-menu"
        hideBackdrop={false}
        className="contextMenu"
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition} // Use the saved anchorPosition instead of anchorEl
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        keepMounted
        open={!!anchorPosition}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <div
          className='w-full flex-col'
            key={option.label}
            onClick={(event) => {
              if (option.subitems) {
                handleSubmenuOpen(event, option);
                event.stopPropagation();
              } else {
                option.action(props.index);
                handleClose();
                event.stopPropagation();
              }
            }}
            selected={option.label === "Pyxis"}
          >
            <div className=''>
              <div onClick={() => setSelectedFolder(index !== selectedFolder ? index : null)} className={ selectedFolder === index ? 'flex highlight-selected': 'flex option-select'}>
                {selectedFolder !== index && <div className='flex-none'><KeyboardArrowRightRoundedIcon fontSize='small'/></div>}
                {selectedFolder === index && <div className='flex-none'><KeyboardArrowDownRoundedIcon fontSize='small'/></div>}
                <div>{option.label} </div>
              </div>
              { option.subitems && selectedFolder === index && <div className=''>
                  <div className='flex-col'>
                  {option && option.subitems.map((item) => {
                    return (
                      <div className='flex'>
                    <MenuItem
                      key={item.label}
                      className='w-full grow pl-5'
                      onClick={(event) => {
                        item.action(props.index);
                        handleSubmenuClose();
                        handleClose();
                        event.stopPropagation();
                      }}
                    >
                      {item.label}
                    </MenuItem>
                    </div>
                  )
                  })}
                  </div>
                </div>
                }
            </div>
          </div>
        ))}

        {/* <Menu
          anchorEl={submenuAnchorEl}
          open={Boolean(submenuAnchorEl)}
          onClose={handleSubmenuClose}
          hideBackdrop={false}
          className="contextMenu"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          {
            activeOption && activeOption.subitems.map(item => {
              return (<MenuItem
                key={item.label}
                onClick={(event) => {
                  item.action(props.index);
                  handleSubmenuClose();
                  handleClose();
                  event.stopPropagation();
                }}
              >
                {item.label}
              </MenuItem>)
            })}
        </Menu> */}
      </Menu>
    </ClickAwayListener>
  );
}
