import React from 'react';
import { connect } from "react-redux";
import './libraryHeader.scss';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { openPopup } from '../../actions/popup';
import { withTranslation } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";

const mapDispatchToProps = dispatch => ({
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  }
})

const mapStateToProps = state => {
  return {
    ...state.currentUser
  }
}

const LibraryHeader = (props) => {
  const { user, t } = props;

  console.log(user, 'useruser')

  const {openPopup} = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const toArcoSearch = () => {
    navigate('/search');
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const logOut = () => {
    localStorage.removeItem("accessToken");
    window.location.reload();
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className='libraryHeader'>
      <Link to='/search' className='hidden sm:flex libraryHeader__searchBtn'>
        <object type="image/svg+xml" data="./images/ico/search.svg" />
        <div
          className='ml-3'>
          Arco Search
        </div>
      </Link>

      <div className='libraryHeader__user relative z-10' onClick={handleToggle}>
        <div className={open ? 'libraryHeader__userName text-orange' : 'libraryHeader__userName'}>{user.first_name}</div>
        <div>
          <div ref={anchorRef} className='libraryHeader__userImg'>
            <img className='w-full' src={user.avatar} alt="edit" />
          </div>

          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={(e) => {handleClose(e);  openPopup({ currentPopup: 'EditProfile', info: {title: 'Profile'}})}}>{t("library.userMenu.profile")}</MenuItem>
                      <MenuItem onClick={() => {
                        window.location.href = "mailto:user@example.com?subject=Subject&body=message%20goes%20here";
                      }}>{t("library.userMenu.tellFriend")}</MenuItem>
                      <MenuItem onClick={(e) => {handleClose(e); openPopup({ currentPopup: 'Feedback', info: {title: 'Contact Us'}})}} >{t("library.userMenu.helpFeedback")}</MenuItem>
                      <div className='border-b border-lightGray'></div>
                      <MenuItem onClick={logOut}>{t("logout")}</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        <div className='hidden sm:block'>{open ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}</div>
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(LibraryHeader));