import {
    GET_COMMON_DATA,
    SET_COMMON_DATA,
    FAIL_COMMON_DATA
} from './index';
 
import {
    getAllMedia,
    getAllUploads,
    getEtudes,
    getAllTags,
    getTagsHasMedia,
    getEtudeHasTags,
    getMe,
    getAllComposers
} from './../service/requests';

import store from './../store';

import { selectPlaylist } from './library';
 
export const setCommonData = (payload) => {
    const { etudes, media, uploads, folders, tags, tagHasMedia, etudeHasTags, composers  } = payload;


    return {
        type: SET_COMMON_DATA,
        etudes,
        media,
        uploads,
        tags,
        folders,
        tagHasMedia,
        etudeHasTags,
        composers
    }
}

export const getCommonData = () => {
    return {
        type: GET_COMMON_DATA
    }
}

export const failCommonData = () => {
    return {
        type: FAIL_COMMON_DATA
    }
}

export const requestCommonData = () => {
    // return (dispatch) => {
    //     grabData(dispatch);
        
    //     return dispatch(getCommonData());
        
    // };

    grabData(store.dispatch);

    return {
        type: FAIL_COMMON_DATA
    }
};

const grabData = (dispatch) => {
    return Promise.all([getEtudes(), getAllMedia(), getAllUploads(), getAllTags(), getTagsHasMedia(), getEtudeHasTags(), getMe(), getAllComposers() ]).then(resps => {
        try {
            const [ respEt, respMe, respUp, respTags, respTagMedia, respEtudeTag, respAcc, respComposers ] = resps;

            const etudes = respEt.data.items;
            const media = respMe.data.ites;
            const uploads = respUp.data.items;
            const tags = respTags.data.items;
            const tagHasMedia = respTagMedia.data.items;
            const etudeHasTags = respEtudeTag.data.items;
            const folders = respAcc.data.folders;
            const composers = respComposers.data.composers;

            etudes.map(etude => {
                const foundCompoer = composers.find(composer => {
                    return composer.composer_id === etude.composer_id;
                })

                etude.composer = foundCompoer;
            });

            uploads.map(upload => {
                const foundCompoer = composers.find(composer => {
                    return composer.composer_id === upload.composer_id;
                })

                upload.composer = foundCompoer;
            });

            folders.map(folder => {
                folder.playlists.map(pl => {
                    pl.items.map(item => {
                        if(item.etude_id) {
                            const etude = etudes.find(etude => etude.etude_id === item.etude_id);
                            const filteredEtTag = etudeHasTags.filter(etTag => etTag.etude_id);

                            item['name'] = etude.etude_name;
                            item['preview'] = etude.etude_sheetmusic;
                            item['difficulty'] = etude.skill_level;
                            item['composer_first_name'] = etude.composer?.composer_first_name || null;
                            item['composer_last_name'] = etude.composer?.composer_last_name || null;
                            item['composer_id'] = etude.composer?.composer_id || null;
                            item['composer'] = (etude.composer?.composer_first_name || '') + ' ' +  (etude.composer?.composer_last_name || '');
                            item['volume'] = etude.etude_opus_number;
                            item['type'] = "Etude / Caprice";
                            item['tag_options'] = [...tags.filter(tag => filteredEtTag.some(fet => fet.tag_id === tag.tag_id))];
                        } else if(item.upload_id) {
                            const upl = uploads.find(upl => upl.upload_id === item.upload_id);

                            item['type'] = (upl.upload_type) ? upl.upload_type.toUpperCase() : upl.type.toUpperCase();
                            item['name'] = (upl.upload_name) ? upl.upload_name : upl.name;
                            item['composer_first_name'] = (upl.composer_first_name) ? upl.composer_first_name : null;
                            item['composer_last_name'] = (upl.composer_last_name) ? upl.composer_last_name : null;
                            item['composer_id'] = (upl.composer_id) ? upl.composer_id : null;
                            item['composer'] = (upl?.composer_first_name || '') + ' ' +  (upl?.composer_last_name || '');
                            item['difficulty'] = (upl.difficulty) ? upl.difficulty : upl.difficulty;
                            item['volume'] = (upl.volume) ? upl.volume : upl.volume;
                            item['keywords'] = (upl.keywords) ? upl.keywords : upl.keywords;
                            item['tag_id'] = (upl.tag_id) ? upl.tag_id : upl.tag_id;
                            item['tagIds'] = (upl.tagIds) ? upl.tagIds : upl.tagIds;
                        }
                    })
                })
            });

            tags.map(tag => {
                const filteredIds = [...new Set(etudeHasTags.filter(eth => eth.tag_id === tag.tag_id).map(eth => eth.etude_id))];

                const filteredEtudes = etudes.filter(etude => filteredIds.some(id => id === etude.etude_id));

                tag.etudes = filteredEtudes;
            });

            const updatedComposers = composers.map(composer => {
                const sort = {};
                const opus = [];

                composer.etudes.map((etude) => {
                    let opusNum = etude.etude_opus_number;

                    if(opusNum || opusNum === 0) {

                        if(!sort[opusNum])
                            sort[opusNum] = [];

                        sort[opusNum].push(etude);
                    };
                });

                composer.uploads?.map((upload) => {
                    let opusNum = upload.volume;

                    if(opusNum || opusNum === 0) {

                        if(!sort[opusNum])
                            sort[opusNum] = [];

                        sort[opusNum].push(upload);
                    };
                });

                for(let key in sort) {
                    const op = {id: key + composer.composer_id, opusNum: key, etudes: sort[key]};

                    opus.push(op);
                };

                composer.opus = opus;

                return composer;
            });

            const payload = {
                etudes,
                media,
                uploads,
                tags,
                tagHasMedia,
                etudeHasTags,
                folders,
                composers: updatedComposers
            }

            const { library } = store.getState();

            if(library.selectedPlaylist) {
                let foundPlaylist = null;

                folders.find(fl => fl.playlists.find(pl => {
                    if(pl.playlist_id === library.selectedPlaylist.playlist_id) {
                        foundPlaylist = pl;
                    }
                }))

                console.log(foundPlaylist, 'foundPlaylist');

                if(foundPlaylist)
                    store.dispatch(selectPlaylist(foundPlaylist));
            }
            setTimeout(() => {
                store.dispatch(setCommonData(payload));
            }, 50);
        } catch (error) {
            console.log(error, 'error');
            store.dispatch(failCommonData());
        }
    });
}


const processEtudeName = (et) => {
    const op = et.etude_opus_number;
    const en = et.etude_name.match(/\d+/);
    if (en) {
        //if (en[0].length == 1 ) {
        //    en[0] = "0" + en[0];
        //}
        if (op && parseInt(op).toFixed) {
            //if (op.length === 1 ) {
            //    op = "0" + op;
            //}
            return et.composer.composer_last_name + ' Op.' + op + ' No.' + en[0];
        }
        else
            return et.composer.composer_last_name + ' No.' + en[0];
    }
    else {
        // just return something sane
        return et.composer.composer_last_name;
    }
}




