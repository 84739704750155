import {
    SET_SELECTED_PLAYLIST
} from '../actions';

const library = ( state = {
    selectedPaylist: null
 },
  action) => {
     const {
        selectedPlaylist
     } = action;
 
     switch (action.type) {
         case SET_SELECTED_PLAYLIST: {
            return {
                ...state,
                selectedPlaylist
            }
        }

         default:
             return state;
     }
 }

export default library;