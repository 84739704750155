import React, { useState } from 'react';
import { connect } from "react-redux";
import GoogleAuth from "../../googleAuth/googleAuth";
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { openPopup, closePopup } from '../../../actions/popup';
import { login, getMe } from '../../../service/requests';
import { currentUser } from '../../../actions/currentUser';
import CircularProgress from '@mui/material/CircularProgress';
import { MainBlueButton } from '../../buttons/buttons';
import { withTranslation } from 'react-i18next';

const mapDispatchToProps = dispatch => ({
    openPopup: (payload) => {
        return dispatch(openPopup(payload));
    },
    currentUser: (payload) => {
        return dispatch(currentUser(payload));
    },
    closePopup: () => {
        return dispatch(closePopup());
      },
})

const Login = (props) => {
    const { openPopup, currentUser, closePopup, t } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const [user, setUser] = useState({
        email: "",
        password: "",
    });

    const [error, setError] = useState({
        email: "",
        password: "",
    });


    const handleChange = (e) => {
        setUser({
            ...user,
            [e.target.id]: e.target.value,
        });

        setError({
            ...error,
            [e.target.id]: false,
        });
    }

     // validation
     const validate = () => {
        let emailError = "";
        let passwordError = "";

        if (!user.email) {
            emailError = "Email can not be blank";
        }

        if (!user.password) {
            passwordError = "Password is too short";
        }

        if (emailError || passwordError) {
            setError({
                email: emailError,
                password: passwordError,
            });
            return false;
        }
        return true;
    };

    const getUser = async () => {
        try {
          const resUser = await getMe();
          if (resUser.data.account) {
            currentUser({user: resUser.data.account});
          } else {
            localStorage.removeItem("accessToken");
          }
        } catch (e) {
          console.log("e", e);
        }
      }
    

    const logIn = async () => {
        const isValid = validate();
    
        if (isValid) {
          setIsLoading(true);
          let resLogin;
          try {
            resLogin = await login(user);
            localStorage.setItem("accessToken", resLogin.data.token);
            getUser();
            setIsLoading(false);
            closePopup();
          } catch (error) {
            if(error.data.code) {
                setErrorMessage("Wrong email or password");
                setIsLoading(false);
                console.log(error);
            }
          }
        }
      };


    return (
        <div className="mt-7 relative authWrapper">
                <TextField size="small" error={error.email} label={t("email")} className='w-full' id="email" value={user.email} onChange={(e) => { handleChange(e) }} variant="outlined" />
                <FormControl size="small" sx={{ m: 1, width: '25ch' }} variant="outlined" className='w-full m-0 mt-3'>
                    <InputLabel htmlFor="outlined-adornment-password">{t("password")}</InputLabel>
                    <OutlinedInput
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        error={error.password}
                        value={user.password}
                        onChange={(e) => { handleChange(e) }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label={t("password")}
                    />
                </FormControl>
            <div className='mt-3 flex justify-end'>
                <div onClick={() => openPopup({ currentPopup: 'ForgotPassword', info: { title: t("forgotPassword") } })} className='text-lightBlue hover:text-blue'>{t("forgotPassword?")}</div>
            </div>

            {errorMessage && <div className="absolute -top-5 right-0 text-xs text-redError">{errorMessage}</div>}

            <div className='w-full mt-3'>
                <MainBlueButton onClick={logIn} text="Log In" isLoading={isLoading} />
            </div>

            <div className='px-5 w-full text-center font-medium mt-3 text-dark'>{t("or")}</div>

            <div className='mt-3'>
                <GoogleAuth setIsGoogleAuthLoading={(isLoading) => setIsLoading(isLoading)} />
            </div>

            <div className='text-lightBlue hover:text-blue mt-7 text-center' onClick={() => openPopup({ currentPopup: 'SignUp', info: { title: t("signUp") } })}>{t("iDontHaveAnAccountYet")}</div>

            {isLoading &&
                <div className='popupLoaderWrapper'><CircularProgress size={50} sx={{ color: '#2f5473' }} /></div>
            }

        </div>
    )
}

export default connect(
    null,
    mapDispatchToProps
  )(withTranslation()(Login));