import React, { useEffect, useState, useCallback } from 'react';
import { connect, useDispatch } from "react-redux";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import { openPopup } from '../../actions/popup';
import './library.scss';
import { baseURL, updatePlaylistItem } from '../../service/requests';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import {openNotification} from '../../actions/notification';
import {requestCommonData} from "../../actions/commonData";
import { withTranslation } from 'react-i18next';

// icons 
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';


const mapStateToProps = (state => ({
    ...state.commonData,
  }))

const mapDispatchToProps = dispatch => ({
    openPopup: (payload) => {
        return dispatch(openPopup(payload));
    },
    openNotification: (payload) => {
        return dispatch(openNotification(payload));
    }
})


const LibraryMobileView = (props) => {
    const { items, openPopup, type, setMobileActiveItem, allPlaylists, copyToPlaylist, moveToPlaylist, copyUploadToPlaylist, uploads, openNotification, t } = props;
    const [selectedItem, setSelectedItem] = useState();
    const [selectedPlaylistToCopy, setSelectedPlaylistToCopy] = useState();
    const [selectedPlaylistToMove, setSelectedPlaylistToMove] = useState();
    const dispatch = useDispatch();
    const [isSelectMode, setIsSelectMode] = useState(false);
    const navigate = useNavigate();


    const handleCopySelectChange = (event) => {
        let playlist = event.target.value;
        copyToPlaylist(selectedItem, playlist.playlist_id)
    };

    const handleCopyUploadChange = (event) => {
        let playlist = event.target.value;
        copyUploadToPlaylist(selectedItem, playlist.playlist_id)
    };

    const handleMoveSelectChange = (event) => {
        let playlist = event.target.value;
        moveToPlaylist(selectedItem, playlist.playlist_id)
    };

    const getDifficulty = (number) => {
        const elements = [];
        for (let i = 0; i < number; i++) {
            elements.push(<div className='search__difficulty' key={i}></div>);
        }
        return elements;
    }

    const onEditComment = async (id, comments) => {
        const payload = {
            id,
            comments
        };

        return updatePlaylistItem(payload);
    }

    const updateRating = async (item, rating) => {
        const payload = {
            id: item.id,
            rating: rating.target.value
        };
        try {
            await updatePlaylistItem(payload);
            dispatch(requestCommonData());
            openNotification({ text: 'Updated', notificationType: "success" });
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (!isSelectMode) {
            setSelectedItem()
            setMobileActiveItem(null)
        }
    }, [isSelectMode])

    return (
        <>
            {type === 'playlist' ?
                <div className='w-full'>
                    <div className={selectedItem ? 'flex w-full md:w-10/12 lg:w-7/12 gap-3' : 'flex w-full md:w-10/12 lg:w-7/12 gap-3 opacity-50 pointer-events-none'}>
                        <FormControl size='small' className='flex-1 w-full'>
                            <InputLabel id="demo-simple-select-label2">Copy to playlist</InputLabel>
                            <Select
                                labelId="demo-simple-select-label2"
                                id="demo-simple-select"
                                value={selectedPlaylistToCopy}
                                label="Select Folder"
                                onChange={handleCopySelectChange}
                            >
                                {allPlaylists.map(playlist => {
                                    return (
                                        <MenuItem value={playlist}>{playlist.playlist_name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                        <FormControl size='small' className='flex-1 w-full'>
                            <InputLabel id="demo-simple-select-label2">Move to playlist</InputLabel>
                            <Select
                                labelId="demo-simple-select-label2"
                                id="demo-simple-select"
                                value={selectedPlaylistToMove}
                                label="Select Folder"
                                onChange={handleMoveSelectChange}
                            >
                                {allPlaylists.map(playlist => {
                                    return (
                                        <MenuItem value={playlist}>{playlist.playlist_name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>

                    <div className='w-fit ml-auto mt-2'>
                        <FormControlLabel
                            control={
                                <Switch checked={isSelectMode}
                                    onChange={() => setIsSelectMode(!isSelectMode)}
                                    inputProps={{ 'aria-label': 'controlled' }} name="selectMode" />
                            }
                            label={`Select mode ${isSelectMode ? 'on' : 'off'}`}
                        />
                    </div>

                    {items.map(item => {
                        return (
                            <div className={selectedItem?.id === item.id ? 'library__item--active' : 'library__item'} key={item.id}
                                onClick={
                                    () => {
                                        if (isSelectMode) {
                                            setSelectedItem(item);
                                            setMobileActiveItem(item);
                                        } else {
                                            if (item.upload_id) {
                                                let currentUploadFile = uploads.find(x => x.upload_id === item.upload_id) 
                                                window.open(`${baseURL}/upload-files/${currentUploadFile.upload_path}`, '_blank', 'noreferrer')
                                              } else {
                                                    navigate(`/etudes/${item.tag_id}/${item.etude_id}`);
                                              }
                                        }
                                    }
                                }
                            >

                                <div className='flex flex-col items-center'>
                                    <div className='text-sm font-semibold'>{t("library.table.type")}</div>
                                    <div className='mt-1'>
                                        {item.upload_id
                                            ? <img src="./images/ico/personalUpload.svg" style={{padding: '4px 0'}} alt="personal upload" />
                                            : <img src="./images/ico/fileType.svg" alt="personal upload" />
                                        }
                                    </div>
                                </div>

                                <div className='library__itemMainInfo'>

                                    <div className='flex flex-col'>
                                        <div className='text-sm font-semibold'>{t("library.table.name")}</div>
                                        <div className='mt-1'>
                                            {item.name}
                                        </div>
                                    </div>

                                    <div className='flex flex-col'>
                                        <div className='text-sm font-semibold'>{t("library.table.composer")}</div>
                                        <div className='mt-1'>
                                            {item.composer}
                                        </div>
                                    </div>

                                    <div className='flex flex-col'>
                                        <div className='text-sm font-semibold'>{t("library.table.volume")}</div>
                                        <div className='mt-1'>
                                            {item.volume}
                                        </div>
                                    </div>

                                    <div className='flex flex-col'>
                                        <div className='text-sm font-semibold'>{t("library.table.difficulty")}</div>
                                        <div className='flex gap-1 mt-1'>
                                            {getDifficulty(item.difficulty)}
                                        </div>
                                    </div>

                                    <div className='flex flex-col'>
                                        <div className='text-sm font-semibold'>{t("library.table.rating")}</div>
                                        <StyledRating
                                            name="simple-controlled"
                                            value={item.rating}
                                            max={4}
                                            icon={<StarRateRoundedIcon />}
                                            emptyIcon={<StarBorderRoundedIcon />}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                            }}
                                            onChange={(newValue) => {
                                                updateRating(item, newValue);
                                              }}
                                        />
                                    </div>

                                    <div className='flex flex-col items-center'>
                                        {!item.upload_id &&
                                            <>
                                                <div className='text-sm font-semibold'>Preview</div>
                                                <div className='mt-1'>
                                                    <Tooltip
                                                        componentsProps={{
                                                            tooltip: {
                                                                sx: {
                                                                    bgcolor: '#ffffff',
                                                                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
                                                                },
                                                            },
                                                        }}
                                                        TransitionComponent={Fade}
                                                        placement="left"
                                                        title={
                                                            <img className='library__preview-open' src={`${baseURL}/content/etude-thumbnails/${item.preview}.png`} alt="note" />
                                                        }
                                                    >
                                                        <img className='library__preview' src={`${baseURL}/content/etude-thumbnails/${item.preview}.png`} alt="note" />
                                                    </Tooltip>
                                                </div>
                                            </>
                                        }
                                    </div>

                                    <div className='flex flex-col flex-1'>
                                        <div className='text-sm font-semibold'>Comment</div>
                                        <div
                                            className={item.comments ? 'libraryTable__comment border border-lightBlue transition hover:border-blue p-2 rounded-xl' : 'border text-lightGray border-lightBlue transition hover:border-blue p-2 rounded-xl'}
                                            onClick={(e) => {e.stopPropagation() ;openPopup({ currentPopup: 'UpdateComment', info: { title:  t("library.actions.editComment"), type: 'comment', data: item, onSave: onEditComment } })}}>
                                                <div className='libraryTable__commentItem'>{item.comments ? item.comments :  t("library.actions.noCommentYet")}</div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        )
                    })
                    }
                </div>

                : <div className='w-full'>
                    <div className={selectedItem ? 'flex w-full gap-3' : 'flex w-full gap-3 opacity-50 pointer-events-none'}>
                        <FormControl size='small' className='flex-1 w-full'>
                            <InputLabel id="demo-simple-select-label2">Copy to playlist</InputLabel>
                            <Select
                                labelId="demo-simple-select-label2"
                                id="demo-simple-select"
                                value={selectedPlaylistToCopy}
                                label="Select Folder"
                                onChange={handleCopyUploadChange}
                            >
                                {allPlaylists.map(playlist => {
                                    return (
                                        <MenuItem value={playlist}>{playlist.playlist_name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>

                    <div className='w-fit ml-auto mt-2'>
                        <FormControlLabel
                            control={
                                <Switch checked={isSelectMode}
                                    onChange={() => { setIsSelectMode(!isSelectMode); setSelectedItem(); setMobileActiveItem() }}
                                    inputProps={{ 'aria-label': 'controlled' }} name="selectMode" />
                            }
                            label={`Select mode ${isSelectMode ? 'on' : 'off'}`}
                        />
                    </div>

                    {items.map(item => {
                        return (
                            <div className={selectedItem?.upload_id === item.upload_id ? 'library__item--active' : 'library__item'} key={item.id}
                                onClick={
                                    () => {
                                        if (isSelectMode) {
                                            setSelectedItem(item);
                                            setMobileActiveItem(item);
                                        } else {
                                            navigate(`/etudes/${item.tag_id}/${item.etude_id}`);
                                        }
                                    }
                                }
                            >

                                <div className='flex flex-col items-center'>
                                    <div className='text-sm font-semibold'>{t("library.table.type")}</div>
                                    <div className='mt-1'>
                                        <img src="./images/ico/personalUpload.svg" alt="personal upload" />
                                    </div>
                                </div>

                                <div className='library__itemMainInfo'>

                                    <div className='flex flex-col'>
                                        <div className='text-sm font-semibold'>{t("library.table.name")}</div>
                                        <div className='mt-1'>
                                            {item.upload_name}
                                        </div>
                                    </div>

                                    <div className='flex flex-col'>
                                        <div className='text-sm font-semibold'>{t("library.table.composer")}</div>
                                        <div className='mt-1'>
                                            {item.composer_first_name} {item.composer_last_name}
                                        </div>
                                    </div>

                                    <div className='flex flex-col'>
                                        <div className='text-sm font-semibold'>{t("library.table.volume")}</div>
                                        <div className='mt-1'>
                                            {item.volume}
                                        </div>
                                    </div>

                                    <div className='flex flex-col'>
                                        <div className='text-sm font-semibold'>{t("library.table.difficulty")}</div>
                                        <div className='flex gap-1 mt-1'>
                                            {getDifficulty(item.difficulty)}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                    })
                    }
                </div>
            }

            {!items.length && <div className='flex flex-col items-center justify-center w-fit mx-auto p-5'>
                <div className="">
                    <object type="image/svg+xml" data="./images/ico/noData.svg" className="w-20" />
                </div>
                <p className='text-sm mt-2'>{t("library.noItemsInPlaylist")}</p>
            </div>}
        </>
    )

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(LibraryMobileView));

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#5DEF2A',
    },
    '& .MuiRating-iconHover': {
        color: '#5DEF2A',
    },
});
