import React, { useState, useEffect, useRef } from 'react'
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ButtonBase } from '@mui/material';
import Search from '../../components/search/search';
import './searchMenu.scss';

import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

import { selectTag, setFilteredTags, selectComposerOpus, clearComposerOpus, hoverComposerOpus } from './../../actions/search';

const mapDispatchToProps = dispatch => ({
    selectTag(tag) {
        return dispatch(selectTag(tag));
    },

    setFilteredTags(tags) {
        return dispatch(setFilteredTags(tags));
    },

    selectComposerOpus(opus) {
        return dispatch(selectComposerOpus(opus));
    },

    hoverComposerOpus(opus) {
        return dispatch(hoverComposerOpus(opus));
    },

    clearComposerOpus() {
        return dispatch(clearComposerOpus())
    }
})
  
const mapStateToProps = (state => ({
    commonData: state.commonData,
    search: state.search
}))

const SearchMenu = (props) => {
    const {
        commonData,
        search,
        setFilteredTags,
        selectTag,
        selectComposerOpus,
        clearComposerOpus,
        hoverComposerOpus
    } = props;

    let { tags, composers } = commonData;
    let { selectedTag, filteredTags, selectedOpus, hoveredOpus, searchWord } = search;

    if(searchWord) {
        tags = tags.filter(tg => new RegExp(`(${searchWord})`, 'img').test(tg.tag_name));

        if(selectedTag) {
            if(new RegExp(`(${searchWord})`, 'img').test(selectedTag.tag_name)) {

            } else {
                if(tags[0])
                    selectTag(tags[0]);
            }
        }
    }

    useEffect(() => {
        if(((!selectedTag && !hoveredOpus && !selectedOpus)) && tags && tags[0]) {
            selectTag(tags[0]);
        }

    }, [selectedTag, tags, hoveredOpus, selectedOpus])

    const searchMenu = useRef(null);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [showKeywords, setShowKeywords] = useState(true);
    const [showComposers, setShowComposers] = useState(false);


    const filterTags = (tagToFilter) => {
        const foundIndex = filteredTags?.findIndex(tag => tag.tag_id === tagToFilter.tag_id);

        let newTags = [...filteredTags];

        if(foundIndex < 0) {
            if(filteredTags.length > 2) {
                return;
            }
            newTags.push(tagToFilter);
        } else {
            newTags = filteredTags.filter(tag => tag.tag_id !== tagToFilter.tag_id);
        }

        setFilteredTags(newTags);
    }

    const selectOpus = (opus, composer) => {
        opus.composer = composer;


        selectComposerOpus(opus);
    }

    useEffect(() => {
        if (window.innerWidth <= 640) {
            function adjustMenuHeight() {
                if (searchMenu.current) {
                    const windowHeight = window.innerHeight;
                    const menuHeight = windowHeight;

                    searchMenu.current.style.height = `${menuHeight}px`;
                }
            }
            window.addEventListener('resize', adjustMenuHeight);
            adjustMenuHeight();

            return () => {
                window.removeEventListener('resize', adjustMenuHeight);
            };
        }
    }, [window.innerWidth]);

    const switchComposer = () => {
        setShowComposers(!showComposers);
    }

    const switchKeywords = () => {
        setShowKeywords(!showKeywords);
    }


    const searchMenuContent = () => {
        return (
            <>
                <div onClick={() => setIsMobileMenuOpen(false)} className={isMobileMenuOpen ? 'libraryMenu__overlay--active' : 'libraryMenu__overlay'}></div>
                <div className={`libraryMenu ${isMobileMenuOpen ? 'libraryMenu--open' : ''}`} ref={searchMenu}>
                    <Link to='/library' className='flex items-center my-5 sm:my-0'>
                        <ButtonBase className=''>
                            <div className='hamburger-iconWrapper'>
                                <ArrowBackIosNewRoundedIcon style={{ color: '#23425C' }} />
                            </div>
                        </ButtonBase>
                        <p className='font-semibold text-lg ml-5 text-white'>Go to my Library</p>
                    </Link>

                    <div className='hidden sm:block'><Search /></div>

                    <div className='searchMenu__data'>
                        <div className='searchMenu__keywords'>
                            <p className='uppercase text-white font-semibold text-md font-extrabold sticky top-0 bg-blue pb-2 hover_custom' onClick={switchKeywords}>KEYWORDS</p>
                            { showKeywords && <div className='mt-1'>
                                {tags.map(tag => {
                                    if((filteredTags && filteredTags[0]) || selectedOpus) {
                                        return (<div key={tag.tag_id} onClick={() => {filterTags(tag)}} className={filteredTags.some(flTag => flTag.tag_id === tag.tag_id) ? 'searchMenu__item searchMenu__item--active' : 'searchMenu__item'}>{tag.tag_name}</div>)
                                    } else {
                                        return (<div key={tag.tag_id} onMouseOver={() => {selectTag(tag)}} onClick={() => {filterTags(tag)}} className={selectedTag?.tag_id !== tag.tag_id ? 'searchMenu__item' : 'searchMenu__item searchMenu__item--active'}>{tag.tag_name}</div>)
                                    }
                                })}
                            </div>}
                        </div>

                        <div className={!showKeywords ? 'searchMenu__composers--only' : showKeywords && !showComposers ? 'searchMenu__composers--min' : 'searchMenu__composers'}>
                            <p className='uppercase text-white font-semibold text-md sticky font-extrabold top-0 bg-blue pb-2 hover_custom' onClick={switchComposer}>COMPOSERS</p>
                            { showComposers && <div className='mt-3'>
                                {
                                    composers.map((composer , i) => {
                                        return (
                                            <div key={i}>
                                                <div className='p-1 cursor-pointer'>{composer.composer_first_name + ' ' + composer.composer_last_name}</div>
                                                <div className='text-grayishBlue ml-3'>
                                                    {composer.opus.map(opus => {
                                                        if((filteredTags && filteredTags[0]) || selectedOpus) {
                                                            return (
                                                                <div onClick={() => (selectedOpus && selectedOpus.id === opus.id) ? clearComposerOpus() : selectOpus(opus, composer)} className={((selectedOpus && selectedOpus.id === opus.id) || (hoveredOpus && hoveredOpus.id === opus.id)) ? 'searchMenu__item searchMenu__item--active' : 'p-1 cursor-pointer searchMenu__item '}>- {opus.opusNum}</div>
                                                            )
                                                        } else {
                                                            return (
                                                                <div onMouseOver={() => {hoverComposerOpus({ ...opus, composer}); console.log('hover', opus)}} onClick={() => (selectedOpus && selectedOpus.id === opus.id) ? clearComposerOpus() : selectOpus(opus, composer)} className={((selectedOpus && selectedOpus.id === opus.id) || (hoveredOpus && hoveredOpus.id === opus.id)) ? 'searchMenu__item searchMenu__item--active' : 'p-1 cursor-pointer searchMenu__item '}>- {opus.opusNum}</div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>}
                        </div>
                    </div>

                    <div className='block sm:hidden z-10 cursor-pointer mt-auto mb-8 pt-5' onClick={() => setIsMobileMenuOpen(false)} >
                        <object
                            type="image/svg+xml"
                            data="./images/ico/closeMenu.svg"
                            style={{ pointerEvents: 'none' }}
                        />
                    </div>
                </div>
            </>
        )
    }


    return (
        <>
            <div className='hidden sm:block libraryMenuWrapper'>
                <div className='libraryMenuWrapperFixed'>
                    <div className='hidden sm:flex h-full'>
                        {searchMenuContent()}
                    </div>
                </div>
            </div>

            <div className="flex sm:hidden h-full">
                {searchMenuContent()}
            </div>

            {!isMobileMenuOpen &&
                <div className='sm:hidden z-10 fixed bottom-10 right-6 cursor-pointer' onClick={() => setIsMobileMenuOpen(true)} >
                    <object
                        type="image/svg+xml"
                        data="./images/ico/menu.svg"
                        style={{ pointerEvents: 'none' }}
                    />
                </div>
            }
        </>




    )
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchMenu);