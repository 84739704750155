import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  // Add other theme customizations here
  typography: {
    fontFamily: 'Montserrat', // Replace with your desired font family
    // You can also customize other typography properties here
  },
  palette: {
    primary: {
      main: '#23425C', // Replace with your desired color
    },
    secondary: {
      main: '#1ac943', // Replace with your desired color
    },
    // Add other color customizations here
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "transparent", // Change this to your desired color or "transparent" to remove it
        },
        "&:hover $notchedOutline": {
          borderColor: "transparent", // Change this to your desired color or "transparent" to remove it
        },
        "&$focused $notchedOutline": {
          borderColor: "transparent", // Change this to your desired color or "transparent" to remove it
        },
      },
    },
  },
});

export default theme;