import React from 'react'
import SearchMenu from '../../components/searchMenu/searchMenu';
import { useNavigate } from 'react-router-dom';
import Search from '../../components/search/search';
import './searchPage.scss';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        search: state.search,
        commonData: state.commonData,
    }
};

const SearchPage = (props) => {
    const { search: { selectedTag, searchWord, filteredTags, selectedOpus, hoveredOpus } } = props;
    const navigate = useNavigate();

    const getDifficulty = (number) => {
        const elements = [];
        
        for (let i = 0; i < number; i++) {
          elements.push(<div className='search__difficulty' key={i}></div>);
        }
      
        return elements;
    }

    let etudesToShow = selectedTag?.etudes || hoveredOpus?.etudes || selectedOpus?.etudes || [];
    let etudesIds = {};

    if(filteredTags.length) {
        filteredTags.map(tag => {
            tag.etudes.map(etude => {
                if(etudesIds[etude.etude_id]) {
                    etudesIds[etude.etude_id].count++;
                } else {
                    etudesIds[etude.etude_id] = {}

                    const payload = {
                        count: 1,
                        data: etude
                    }

                    etudesIds[etude.etude_id] = payload;
                }
            })
        });

        etudesToShow = [];
    }

    for(let key in etudesIds) {
        const etud = etudesIds[key];

        // console.log(etud, idTags.length);

        if(etud.count === filteredTags.length) {
            etudesToShow.push(etud.data);
        }
    }
    
    console.log(selectedOpus, 'selectedOpus', etudesToShow);

    if(selectedOpus) {
        etudesToShow = etudesToShow.filter(etude => {
            if(etude.etude_id) {
                return (etude.etude_opus_number === selectedOpus.opusNum && etude.composer_id === selectedOpus.composer.composer_id)
            } else {
                return true
            }
        })
    }



    return (
        <div className='flex flex-col sm:flex-row'>
            <SearchMenu />
            <div className='content flex-1 py-1 sm:py-10 px-5 sm:px-8 pb-20'>
                <div className='search__content'>

                    <div className='block sm:hidden'><Search/></div>

                {(selectedTag && !selectedOpus && !filteredTags[0]) && <div className='search__title'>{selectedTag.tag_name}</div>}
                {(selectedOpus && !hoveredOpus && !filteredTags[0]) && <div className='search__title'>{selectedOpus.composer.composer_first_name} {selectedOpus.composer.composer_last_name} Op.{selectedOpus.opusNum}</div>}
                {(hoveredOpus && !selectedOpus && !filteredTags[0]) && <div className='search__title'>{hoveredOpus.composer.composer_first_name} {hoveredOpus.composer.composer_last_name} Op.{hoveredOpus.opusNum}</div>}
                {(selectedOpus && filteredTags[0]) && <div className='search__title'>{selectedOpus.composer.composer_first_name} {selectedOpus.composer.composer_last_name} Op.{selectedOpus.opusNum}, {filteredTags.map(tg => tg.tag_name).join(', ')}</div>}
                {(!selectedOpus && filteredTags[0]) && <div className='search__title'>{filteredTags.map(tg => tg.tag_name).join(', ')}</div>}

                <div className='search__items'>
                    { etudesToShow.map(etude => {
                        let { etude_opus_number, etude_sheetmusic, skill_level, upload_id, difficulty, upload_name, volume, etude_name  } = etude;

                        let imagePath = `https://dev.arcomusic.ai/content/etude-thumbnails/${etude_sheetmusic}.png`

                        if(upload_id) {
                            imagePath = 'https://dev.arcomusic.ai/images/upload-default.png';
                            skill_level = difficulty;
                            etude_name = upload_name
                        }

                        if(selectedTag) {
                            const op = selectedOpus || hoveredOpus || null;
                            const { composer } = etude;
                            const link = (filteredTags && filteredTags[0]) ? '/filtered/' + (op?.composer?.composer_id || 'none') + '/' + (op?.opusNum || 'none') + '/' + (filteredTags.map(tag => tag.tag_id).join(':')) + '/' + etude.etude_id : '/etudes/' + selectedTag.tag_id + '/' + etude.etude_id;

                            return <div key={etude.etude_id} onClick={() => { if(!upload_id) navigate(link)}} className='search__item'>
                                <div className='flex justify-between items-center'>
                                    <p className='font-bold'>{composer.composer_first_name + ' ' + composer.composer_last_name} </p>
                                    <div className='flex gap-0.5'>
                                        {getDifficulty(skill_level)}
                                    </div>
                                </div>
                                { !upload_id && <p>{composer.composer_last_name + ' - ' + etude_name}</p>}
                                { upload_id && <p>{volume + ' - ' + upload_name}</p>}
                                <img className='w-full mt-3' src={imagePath}/>
                            </div>
                        }

                        if(hoveredOpus || selectedOpus) {
                            const op = selectedOpus || hoveredOpus;
                            const link = (filteredTags && filteredTags[0]) ? '/filtered/' + (op?.composer?.composer_id || 'none') + '/' + (op?.opusNum || 'none') + '/' + (filteredTags.map(tag => tag.tag_id).join(':')) + '/' + etude.etude_id : '/composers/' + op.composer.composer_id + '/' + op.opusNum + '/' + etude.etude_id;

                            const { composer } = op;
                            return <div key={etude.etude_id} onClick={() => { if(!upload_id) navigate(link)}} className='search__item'>
                                <div className='flex justify-between items-center'>
                                    {/* <p className='font-bold'>{composer.composer_first_name + ' ' + composer.composer_last_name} </p> */}
                                    <div className='flex gap-0.5'>
                                        {getDifficulty(skill_level)}
                                    </div>
                                </div>
                                { !upload_id && <p>{composer.composer_last_name + ' - ' + etude_name}</p>}
                                { upload_id && <p>{volume + ' - ' + upload_name}</p>}
                                <img className='w-full mt-3' src={imagePath}/>
                            </div>
                        }
                    })}
                </div>

                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(SearchPage);