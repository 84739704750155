import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import ua from './translations/ua.json';
import us from './translations/us.json';

const resources = {
   ua: { 
      translation : ua
   },
  
   us: {
      translation: us
   }
};

i18n
   .use(LanguageDetector)
   .use(initReactI18next)
   .init({
      resources,
      fallbackLng: "us", 
      debug: true,
      interpolation: {
         escapeValue: false 
      }
   });

i18n.changeLanguage()

export default i18n;