import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import './buttons.scss';

export const MainBlueButton = (props) => {
   return (
      <ButtonBase onClick={props.onClick} className='rounded w-full'>
         <div className={props.disabled || props.isLoading ? "btn mainBlueButton--disabled" : "btn mainBlueButton"}>{props.isLoading && <CircularProgress size={14} sx={{ color: 'white'}} className="mr-4"/> } {props.text}</div>
      </ButtonBase>
   )
}

export const BlueButton = (props) => {
   return (
      <ButtonBase onClick={props.onClick} className='rounded w-full'>
         <div className={props.disabled || props.isLoading ? "btn blueButton--disabled" : "btn blueButton"}>{props.isLoading && <CircularProgress size={14} sx={{ color: 'white'}} className="mr-4"/> } {props.text}</div>
      </ButtonBase>
   )
}

export const AccentButton = (props) => {
   return (
      <ButtonBase onClick={props.onClick} className='rounded'>
         <div className={props.disabled ? "btn btn--disabled" : "btn blueButton"}>{props.text}</div>
      </ButtonBase>
   )
}

export const PlainButton = (props) => {
   return (
      <ButtonBase onClick={props.onClick} className='rounded'>
         <div className={props.disabled ? "btn btn--disabled" : "btn plainButton"}>{props.text}</div>
      </ButtonBase>
   )
}

export const SaveButton = (props) => {
   return (
      <ButtonBase disabled={props.disabled} className={props.className ? props.className : 'rounded'} onClick={props.onClick}>
         <div className={props.disabled ? "btn btn--disabled" : "btn blueButton"}>{props.text}</div>
      </ButtonBase>
   )
}


export const CancelButton = (props) => {
   return (
      <ButtonBase onClick={props.onClick} className='rounded'>
         <div className={props.disabled ? "btn btn--disabled" : "btn cancelBtn"}>{props.text}</div>
      </ButtonBase>
   )
}