import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import TextField from '@mui/material/TextField';
import validator from 'validator';
import { SaveButton, CancelButton } from '../../buttons/buttons';
import { closePopup } from "../../../actions/popup";
import { openNotification } from "../../../actions/notification";
import { forgotPassword } from './../../../service/requests';
import { withTranslation } from 'react-i18next';

const ForgotPassword = (props) => {
    const { closePopup, t } = props;
    const [email, setEmail] = useState();
    const [emailError, setEmailError] = useState();

    const validate = () => {
        if (!validator.isEmail(email)) {
            setEmailError('Invalid email');
            return false;
        }
        setEmailError('');
        return true;
    };

    const onResetPassword = () => {
        const isValid = validate();

        if (isValid) {
            console.log('reset password by this email', email)
            forgotPassword(email);
            openNotification({ text: "Message sent", notificationType: "success" });
            closePopup()
        }
    }

    return (
        <div className='mt-7 w-full sm:w-96'>
            <TextField
                autoFocus
                error={emailError}
                size='small'
                value={email}
                onChange={(e) => { setEmail(e.target.value); setEmailError(false) }}
                onKeyDown={(event) => event.key === 'Enter' && email && onResetPassword()}
                label={t("email")}
                placeholder={t("library.placeholder.enterYourEmail")}
                className='w-full'
                variant="outlined"
            />
            <div className='mt-7 ml-auto w-min flex gap-3'>
                <SaveButton text='OK' disabled={!email} onClick={onResetPassword} />
                <CancelButton text={t("library.actions.cancel")} onClick={() => closePopup()} />
            </div>
        </div>
    )
}

export default connect(
    null,
    { closePopup, openNotification }
  )(withTranslation()(ForgotPassword));
  