import React, { useState, useEffect, useRef } from 'react'
import { Link } from "react-router-dom";
import LibraryHeader from '../libraryHeader/libraryHeader';
import LibraryMenu from '../libraryMenu/libraryMenu';
import './libraryMenuWrapper.scss';

const LibraryMenuWrapper = (props) => {

    const {setSelectedPlaylist, setFolderId, setIsUploadsOpen, draggedItem, copyUploadToPlaylist, copyToPlaylist} = props;
    const [isOpenLibraryMenu, setIsOpenLibraryMenu] = useState(false);
    const [open, setOpen] = useState(false);
    const menuRef = useRef(null);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpenLibraryMenu(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className='libraryMenuWrapper'>
                <div className='libraryMenuWrapperFixed'>
                    <div className='flex items-center justify-between'>
                        <Link to='/' className='w-16 sm:w-20'>
                            <object type="image/svg+xml" className='w-full pointer-events-none' data="./images/logoWhite.svg" />
                        </Link>
                        <div className='block sm:hidden'>
                            <LibraryHeader />
                        </div>
                    </div>
                    <div className='hidden sm:flex h-full'>
                        <LibraryMenu copyUploadToPlaylist={copyUploadToPlaylist} copyToPlaylist={copyToPlaylist} draggedItem={draggedItem} setSelectedPlaylist={setSelectedPlaylist} setIsUploadsOpen={setIsUploadsOpen} setFolderId={setFolderId} />
                    </div>
                </div>
            </div>
            <div className="flex sm:hidden h-full">
                <LibraryMenu copyUploadToPlaylist={copyUploadToPlaylist} copyToPlaylist={copyToPlaylist} draggedItem={draggedItem} setSelectedPlaylist={setSelectedPlaylist} setIsUploadsOpen={setIsUploadsOpen} setFolderId={setFolderId} isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen} />
            </div>

            {!isMobileMenuOpen &&
                <div className='sm:hidden z-10 fixed bottom-10 right-6 cursor-pointer' onClick={() => setIsMobileMenuOpen(true)} >
                    <object
                        type="image/svg+xml"
                        data="./images/ico/menu.svg"
                        style={{ pointerEvents: 'none' }}
                    />
                </div>
            }

        </>
    )
}

export default LibraryMenuWrapper