import React, { useState } from 'react';
import { connect } from "react-redux";
import TextField from '@mui/material/TextField';
import { SaveButton, CancelButton, AccentButton } from '../../buttons/buttons';
import './editProfile.scss';
import { openPopup, closePopup } from '../../../actions/popup';
import { updateUserInfo } from '../../../service/requests';
import { withTranslation } from 'react-i18next';


const mapStateToProps = state => {
  return {
    ...state.currentUser
  }
}
const mapDispatchToProps = dispatch => ({
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  },
  closePopup: () => {
    return dispatch(closePopup());
  },
})

const EditProfile = (props) => {

  const { openPopup, closePopup, user: userData, t } = props;

  const [user, setUser] = useState({
    email: userData.user_email || '',
    firstName: userData.first_name || '',
    lastName: userData.last_name || ''
  });

  const onUpdateUserInfo = async (event) => {
    try {
      event.preventDefault();

      const res = await updateUserInfo({
        first_name:user.firstName,
        id: userData.id,
        last_name: user.lastName
      })
      if(res.data) {
        closePopup()
      }
    } catch(e) {
      console.log(e)
    }

  }

  return (
    <div className='mt-3'>
      <form onSubmit={onUpdateUserInfo}>
      <div className='editProfile__imgWrapper' onClick={() => openPopup({ currentPopup: 'UploadImage', info: { title: 'Please upload your photo' } })}>
        <img className='w-full' src={userData?.avatar} alt="edit" />
      </div>
      <div className='block sm:hidden w-max mx-auto mt-3' onClick={() => openPopup({ currentPopup: 'UploadImage', info: { title: 'Please upload your photo' } })}>
        <AccentButton text="Update photo" />
      </div>
      <TextField size='small' label="Email" className='w-full mt-5' variant="outlined" disabled value={user.email} onChange={(e) => setUser({ ...user, email: e.target.value })} />
      <TextField size='small' label="First Name" className='w-full  mt-4' variant="outlined" value={user.firstName} onChange={(e) => setUser({ ...user, firstName: e.target.value })} />
      <TextField size='small' label="Last Name" className='w-full  mt-4' variant="outlined" value={user.lastName} onChange={(e) => setUser({ ...user, lastName: e.target.value })} />
      <div className='mt-7 ml-auto w-min flex gap-3'>
        <SaveButton text={t("library.actions.save")} onClick={onUpdateUserInfo} />
        <CancelButton text={t("library.actions.cancel")} onClick={() => closePopup()} />
        <button hidden='true' type="submit"></button>
      </div>
      </form>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EditProfile));