import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import EtudesMenu from '../../components/etudesMenu/etudesMenu';
import PdfReader from './pdfReader';
import defaultPdf from '../../weather.pdf';
import { useParams } from 'react-router-dom';
import { baseURL } from '../../service/requests';
// import { pdfjs } from 'react-pdf';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/legacy/build/pdf.worker.min.js',
//     import.meta.url,
// ).toString();


const mapStateToProps = (state => ({
    ...state.commonData,
}))

const EtudesPage = (props) => {
    const { etudes } = props;
    const { tagId, etudeId } = useParams();
    const [currentEtude, setCurrentEtude] = useState();

    useEffect(() => {
        if (etudes) {
            const foundEtude = etudes.find(etude => etude.etude_id == etudeId);
            setCurrentEtude(foundEtude);
        }
    }, [etudeId, etudes]);

    return (
        <div className='flex flex-col sm:flex-row'>
            <EtudesMenu currentEtude={currentEtude} tagId={tagId} />
            <div className='content flex-1 py-1 sm:py-10 px-5 sm:px-8'>
                <div className='search__content'>
                    {currentEtude &&
                        <PdfReader pdfUrl={`${baseURL}/content/etude-sheetmusic/${currentEtude.etude_sheetmusic}.pdf`} etudeName={currentEtude.etude_name} currentEtude={currentEtude} />
                    }
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(EtudesPage);
