import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ButtonBase } from '@mui/material';
import '../searchMenu/searchMenu.scss';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useNavigate } from "react-router-dom";

const mapStateToProps = (state => ({
    ...state.commonData,
}))


const FilteredMenu = (props) => {
    const { currentEtude, composers, composerId, opus, tagIds, tags } = props;
    const searchMenu = useRef(null);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [currentComposer, setCurrentComposer] = useState();
    const [currentOpus, setCurrentOpus] = useState();
    const [filteredTags, setFilteredTags] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        if (window.innerWidth <= 640) {
            function adjustMenuHeight() {
                if (searchMenu.current) {
                    const windowHeight = window.innerHeight;
                    const menuHeight = windowHeight;

                    searchMenu.current.style.height = `${menuHeight}px`;
                }
            }
            window.addEventListener('resize', adjustMenuHeight);
            adjustMenuHeight();

            return () => {
                window.removeEventListener('resize', adjustMenuHeight);
            };
        }

    }, [window.innerWidth]);

    useEffect(() => {

        if (composers && composerId) {
            const composer = composers.find(composer => composer.composer_id == composerId);

            if(composer) {
                setCurrentComposer(composer);

                const foundOpus = composer?.opus?.find(op => op.opusNum === opus);

                setCurrentOpus(foundOpus);
            }
        }

        if(tagIds) {

            if(tags && tags[0]) {
                const fltTags = tags.filter(tag => tagIds.some(tg => tg == tag.tag_id));

                setFilteredTags(fltTags);
            }
        }

    }, [composers, composerId]);

    const etudesIds = {};
    let etudesToShow = [];


    if(filteredTags.length) {
        filteredTags.map(tag => {
            tag.etudes.map(etude => {
                if(etudesIds[etude.etude_id]) {
                    etudesIds[etude.etude_id].count++;
                } else {
                    etudesIds[etude.etude_id] = {}

                    const payload = {
                        count: 1,
                        data: etude
                    }

                    etudesIds[etude.etude_id] = payload;
                }
            })
        });

        etudesToShow = [];
    }

    for(let key in etudesIds) {
        const etud = etudesIds[key];

        // console.log(etud, idTags.length);

        if(etud.count === filteredTags.length) {
            etudesToShow.push(etud.data);
        }
    }
    
    console.log(currentOpus, 'selectedOpus', etudesToShow);

    if(currentOpus) {
        etudesToShow = etudesToShow.filter(etude => {
            if(etude.etude_id) {
                return (etude.etude_opus_number === currentOpus.opusNum && etude.composer_id === currentComposer.composer_id)
            } else {
                return true
            }
        })
    }


    const etudesMenuContent = () => {
        return (
            <>
                <div onClick={() => setIsMobileMenuOpen(false)} className={isMobileMenuOpen ? 'libraryMenu__overlay--active' : 'libraryMenu__overlay'}></div>
                <div className={`libraryMenu ${isMobileMenuOpen ? 'libraryMenu--open' : ''}`} ref={searchMenu}>
                    <Link to='/search' className='flex items-center mt-5'>
                        <ButtonBase className=''>
                            <div className='hamburger-iconWrapper'>
                                <ArrowBackIosNewRoundedIcon style={{ color: '#23425C' }} />
                            </div>
                        </ButtonBase>
                        <p className='font-semibold text-lg ml-5 text-white'>Go to Search</p>
                    </Link>

                    {(currentComposer || currentEtude || currentOpus || filteredTags) &&
                        <>
                            {filteredTags && !currentComposer &&  <p className='text-2xl text-skyBlue mt-5 font-medium'>{filteredTags.map(tag => tag.tag_name).join(', ')}</p> }
                            {filteredTags && currentComposer && <p className='text-2xl text-skyBlue mt-5 font-medium'>{filteredTags.map(tag => tag.tag_name).join(', ')}, {currentComposer.composer_first_name} {currentComposer.composer_last_name}</p> }

                            <div className='searchMenu__data'>
                                <div className='searchMenu__etudes'>
                                    <p className='uppercase text-white font-semibold text-sm mt-3'>Etudes</p>
                                    <div className='mt-5 flex flex-col ml-2 searchMenu__etudesItems'>
                                        {etudesToShow.map(etude => {
                                            return (
                                                <div className={etude.etude_id == currentEtude.etude_id ? 'searchMenu__item--active' : 'searchMenu__item'} onClick={() => navigate(`/filtered/${composerId}/${opus}/${tagIds.join(':')}${etude.etude_id}`)}>{etude.etude_name}</div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div className='block sm:hidden z-10 cursor-pointer mt-auto mb-8 pt-5' onClick={() => setIsMobileMenuOpen(false)} >
                        <img src="/images/ico/closeMenu.svg" />
                    </div>
                </div>
            </>
        )
    }


    return (
        <>
            <div className='hidden sm:block libraryMenuWrapper'>
                <div className='libraryMenuWrapperFixed'>
                    <div className='hidden sm:flex h-full'>
                        {etudesMenuContent()}
                    </div>
                </div>
            </div>
            <div className="flex sm:hidden h-full">
                {etudesMenuContent()}
            </div>

            {!isMobileMenuOpen &&

                <div className='sm:hidden z-10 fixed bottom-10 right-6 cursor-pointer' onClick={() => setIsMobileMenuOpen(true)}>
                    <img src="/images/ico/menu.svg" />
                </div>
            }
        </>
    )
}

export default connect(mapStateToProps, null)(FilteredMenu);