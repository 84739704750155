import React, { useState, useEffect, useRef } from 'react'
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ButtonBase } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import { openPopup } from '../../actions/popup';
import { openNotification } from "../../actions/notification";
import ContextMenu from "../../components/contextMenu/contextMenu";
import {deletePlaylistItem, duplicatePlaylis, deleteFolder, movePlaylist, copyPlaylist, duplicatePlaylist, deletePlaylist} from '../../service/requests';
import { selectPlaylist } from '../../actions/library';
import { requestCommonData } from "../../actions/commonData";
import './libraryMenu.scss';
import { withTranslation } from 'react-i18next';


const mapStateToProps = (state => ({
    ...state.commonData,
    library: state.library
}))

const mapDispatchToProps = dispatch => ({
    openPopup: (payload) => {
        return dispatch(openPopup(payload));
    },
    selectPlaylist(pl) {
        return dispatch(selectPlaylist(pl));
    },
    openNotification: (payload) => {
        return dispatch(openNotification(payload));
    },
})

const LibraryMenu = (props) => {

    const { setIsMobileMenuOpen, isMobileMenuOpen, openPopup, folders, setSelectedPlaylist, setFolderId, setIsUploadsOpen, library, selectPlaylist: setActivePlaylist, openNotification, t, draggedItem, copyToPlaylist, copyUploadToPlaylist } = props;
    const { selectedPlaylist: activePlaylist } = library;
    const [isOpenLibraryMenu, setIsOpenLibraryMenu] = useState(false);
    const [openFolders, setOpenFolders] = useState([]);
    const menuRef = useRef(null);
    const libraryMenu = useRef(null);
    const [contextMenu, setContextMenu] = React.useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [activeUploads, setActiveUploads] = useState();
    const [openPlaylist, setOpenPlaylist] = useState();
    const [filteredfolders, setFilteredfolders] = useState([]);
    const [hoveredElement, setHoveredElement] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if(activePlaylist) {
            const activeFolder = folders.filter(folder => folder.playlists.some(playlist => playlist.playlist_id === activePlaylist.playlist_id))
            setOpenFolders([...openFolders, ...folders.filter(fl => fl?.playlists?.length).map(fl => fl.folder_name)]);
        }
    }, [activePlaylist]);

    useEffect(() => {
        setHoveredElement(null)
    }, [activeUploads])

    useEffect(() => {
        if (window.innerWidth <= 640) {
            function adjustMenuHeight() {
                if (libraryMenu.current) {
                    const windowHeight = window.innerHeight;
                    const menuHeight = windowHeight;

                    libraryMenu.current.style.height = `${menuHeight}px`;
                }
            }

            window.addEventListener('resize', adjustMenuHeight);
            adjustMenuHeight();

            return () => {
                window.removeEventListener('resize', adjustMenuHeight);
            };
        }
    }, [window.innerWidth]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpenLibraryMenu(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const onSelectPlaylist = (playlist, folderId) => {
        setActivePlaylist(playlist)
        setFolderId(folderId)
        setActiveUploads(false)
        setIsUploadsOpen(false)
    }


    const handleContextMenu = (event, item) => {
        event.preventDefault();
        event.stopPropagation();
        setContextMenu(
            contextMenu === null
                ? {
                    top: event.clientY - 6,
                    left: event.clientX + 2,
                    item
                }
                : 
                null
        );
        if(item.playlist_id) {
            setFilteredfolders(folders.filter(folder =>
                !folder.playlists.some(playlist => playlist.playlist_id === item.playlist_id)))
        }

    };

    const handleCloseContextMenu = () => {
        setContextMenu(null);
        setSelectedItem(null)
    };

    const onDeleteFolder = async (item) => {
        try {
          await deleteFolder(item.folder_id);
          dispatch(requestCommonData());
         openNotification({ text: 'Folder was deleted', notificationType: "success" });
        } catch (e) {
          console.log(e)
        }
      }

      const onDeletePlaylist = async (item) => {
        let currentFolder = folders.find(folder =>
            folder.playlists.some(playlist => playlist.playlist_id === item.playlist_id));
        try {
          await deletePlaylist(currentFolder.folder_id, item.playlist_id);
          dispatch(requestCommonData());
          openNotification({ text: 'Playlist was deleted', notificationType: "success" });

        } catch (e) {
          console.log(e)
        }
      }

      const moveToFolder = async (item, folderId) => {
        try {
            const res = await movePlaylist(folderId, item.playlist_id);
            dispatch(requestCommonData());
            openNotification({ text: 'Playlist was moved', notificationType: "success" });
            console.log('res', res)
          } catch (e) {
            console.log(e)
          }
      }

      const copyToFolder = async (item, folderId) => {
        try {
            const res = await copyPlaylist(folderId, item.playlist_id);
            dispatch(requestCommonData());
            openNotification({ text: 'Playlist was copied', notificationType: "success" });
            console.log('res', res)
          } catch (e) {
            console.log(e)
          }
      }

    const onDuplicatePlaylist = async (item) => {
        let currentFolder = folders.find(folder =>
            folder.playlists.some(playlist => playlist.playlist_id === item.playlist_id));
        try {
            await duplicatePlaylist(currentFolder.folder_id, item.playlist_id);
            dispatch(requestCommonData());
            openNotification({ text: 'The playlist was duplicated', notificationType: "success" });
        } catch (e) {
            console.log(e)
        }
    }


    const folderOptions = [
        { label: t("library.actions.newPlaylist"), action: (item) => openPopup({ currentPopup: 'CreatePlaylist', info: { title: t("library.actions.newPlaylist"), data: item, isEditMode: true } }) },
        { label: t("library.actions.renameFolder"), action: (item) => openPopup({ currentPopup: 'Rename', info: { title: t("library.actions.renameFolder"), type: 'folder', data: item.folder_name, folderId: item.folder_id }})},
        { label: t("library.actions.deleteFolder"), action: (item) => openPopup({ currentPopup: 'Confirmation', info: { title: `${t("library.actions.delete")}?`, confirmationText: t("library.message.areYouShureYouWantToDeleteThisItem"), onConfirm: () => onDeleteFolder(item) } })},
    ]

    const playlistOptions = [
        {
            label: t("library.actions.moveToFolder"),
            subitems: filteredfolders.map(folder => {
              return {
                label: folder.folder_name,
                action: (item) => moveToFolder(item, folder.folder_id),
              };
            })
          },
          {
            label: t("library.actions.copyToFolder"),
            subitems: filteredfolders.map(folder => {
              return {
                label: folder.folder_name,
                action: (item) => copyToFolder(item, folder.folder_id),
              };
            })
          },

        { label: t("library.actions.rename"), action: (item) => openPopup({ currentPopup: 'Rename', info: { title: t("library.actions.renamePlaylist"), type: 'playlist', data: item.playlist_name, playlistId: item.playlist_id } }) },
        { label: t("library.actions.duplicate"), action: onDuplicatePlaylist },
        { label: t("library.actions.delete"), action: (item) => openPopup({ currentPopup: 'Confirmation', info: { title: `${t("library.actions.delete")}?`, confirmationText: t("library.message.areYouShureYouWantToDeleteThisItem"), onConfirm: () => onDeletePlaylist(item) } })},

    ]

    const toggleMenu = () => {
        setIsOpenLibraryMenu(!isOpenLibraryMenu);
    };

    const toggleFolder = (folderName) => {
        if (openFolders.includes(folderName)) {
            setOpenFolders(openFolders.filter((folder) => folder !== folderName));
        } else {
            setOpenFolders([...openFolders, folderName]);
        }
    };

    const handleDrop = (e, playlistId) => {
        e.preventDefault();

        if(activeUploads) {
            return copyUploadToPlaylist(draggedItem, playlistId)
        }

        if(activeUploads || !activeUploads && playlistId != activePlaylist.playlist_id) {
            setHoveredElement(null);

            if(draggedItem.upload_id) {
                // copyUploadToPlaylist(draggedItem, playlistId)
                copyToPlaylist(draggedItem, playlistId)
            } else {
                copyToPlaylist(draggedItem, playlistId)
            }
        }
    };

    const handleDragEnter = (e, elementId) => {
        e.preventDefault();
        if (e.currentTarget === e.target) {
            setHoveredElement(elementId);
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setHoveredElement(null);
        }
    };

    const renderFolder = (folder, i) => {

        const isFolderOpen = openFolders.includes(folder.folder_name);

        return (
            <div className='text-white cursor-pointer mt-2' key={i} onContextMenu={(event) => handleContextMenu(event, folder)} >
                <ListItem onClick={() => toggleFolder(folder.folder_name)}>
                    {isFolderOpen ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowRightRoundedIcon />}
                    <ListItemText className='ml-3' primary={folder.folder_name} />
                </ListItem>
                <Collapse in={isFolderOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {folder.playlists.length > 0 && folder.playlists.map(playlist => {
                            return (
                                <ListItem
                                    style={hoveredElement === playlist.playlist_id && (!activeUploads ? hoveredElement != activePlaylist.playlist_id : true)
                                        ? { background: '#ffffff59' }
                                        : {}}
                                    onDrop={(e) => handleDrop(e, playlist.playlist_id)}
                                    onDragOver={(e) => e.preventDefault()}
                                    onDragEnter={(e) => handleDragEnter(e, playlist.playlist_id)}
                                    onDragLeave={(e) => handleDragLeave(e)}
                                    onContextMenu={(event) => handleContextMenu(event, playlist)}
                                    onClick={() => onSelectPlaylist(playlist, folder.folder_id)}
                                    className={activePlaylist && activePlaylist.playlist_id === playlist.playlist_id ? 'searchMenu__item--active' : 'searchMenu__item'} key={playlist.playlist_id}>
                                    <ListItemText className='libraryMenu__listText' primary={playlist.playlist_name} />
                                </ListItem>
                            )
                        })}

                        {!!contextMenu?.top && (
                            <ContextMenu
                                anchorPosition={{ top: contextMenu.top, left: contextMenu.left }}
                                options={contextMenu.item.folder_id ? folderOptions : playlistOptions}
                                handleClose={() => handleCloseContextMenu()}
                                index={contextMenu?.item}
                            />
                        )}
                    </List>
                </Collapse>
            </div>
        )
    }

    return (
        <>
            <div onClick={() => setIsMobileMenuOpen(false)} className={isMobileMenuOpen ? 'libraryMenu__overlay--active' : 'libraryMenu__overlay'}></div>
            <div className={`libraryMenu ${isMobileMenuOpen ? 'libraryMenu--open' : ''}`} ref={libraryMenu}>
                <div ref={menuRef} className={`hamburger-menuWrapper flex items-center mt-5 ${isOpenLibraryMenu ? 'open' : ''}`}>
                    <ButtonBase className=''>
                        <div className='hamburger-iconWrapper' onClick={toggleMenu}>
                            <div className="hamburger-icon">
                                <div className="bar"></div>
                                <div className="bar"></div>
                                <div className="bar"></div>
                            </div>
                        </div>
                    </ButtonBase>
                    <ul className="libraryMenu__menuItems">
                        <Link to='/'
                            className='libraryMenu__menuItem'>
                            Home
                        </Link>
                        <Link to='/search'
                            className='libraryMenu__menuItem'>
                            Arco Search
                        </Link>
                        <Link to='/'
                            className='libraryMenu__menuItem'>
                            ArcoPro (preview)
                        </Link>
                        <Link to='/'
                            className='libraryMenu__menuItem'>
                            ArcoTV
                        </Link>
                        <Link to='/'
                            className='libraryMenu__menuItem'>
                            About Us
                        </Link>
                    </ul>
                    <p className='font-semibold text-xl ml-4 text-white'>My Library</p>
                </div>

                <Link to='/search' className='flex sm:hidden mt-5 libraryHeader__searchBtn'>
                    <object type="image/svg+xml" data="./images/ico/search.svg" />
                    <div
                        className='ml-3'>
                        Arco Search
                    </div>
                </Link>


                <div className='mt-5 sm:mt-10'>
                    <p className='uppercase text-white font-semibold text-sm'>my content</p>
                    <div className='w-full mt-3' onClick={() => { setIsUploadsOpen(true); setActiveUploads(true); setActivePlaylist(); setOpenFolders([])}}>
                        <div className={activeUploads ? 'searchMenu__item--active leading-8' : 'searchMenu__item leading-8'} >{t("myUploads")}</div>
                    </div>

                    <div className='flex justify-between items-center mt-5 sm:mt-10'>
                        <p className='uppercase text-white font-semibold text-sm'>{t("library.menu.myPlaylist")}</p>
                        <ButtonBase onClick={() => openPopup({ currentPopup: 'CreatePlaylist', info: { title: t("library.actions.newPlaylist") } })}>
                            <div className='libraryMenu__addPlaylistBtn'><div>+</div></div>
                        </ButtonBase>
                    </div>
                </div>

                <div className='libraryMenu__folders'>
                    {folders.length > 0 && folders.map((folder, i) => {
                        return (
                            renderFolder(folder, i)
                        )
                    })}
                </div>


                <div className='libraryMenu__menuBottomButtons items-end sm:items-center flex justify-between flex-row sm:flex-col'>

                    <div className='block sm:hidden z-10 cursor-pointer' onClick={() => setIsMobileMenuOpen(false)} >
                        <object
                            type="image/svg+xml"
                            data="./images/ico/closeMenu.svg"
                            style={{ pointerEvents: 'none' }}
                        />
                    </div>

                    <div>

                        <ButtonBase className='rounded mx-auto w-40 block' onClick={() => openPopup({ currentPopup: 'CreateFolder', info: { title: t("library.actions.newFolder") } })}>
                            <div className='libraryMenu__menuBottomBtn'>
                                <AddRoundedIcon />
                                <span className='ml-3'>{t("library.actions.newFolder")}</span>
                            </div>
                        </ButtonBase>
                        <ButtonBase className='rounded mx-auto w-40 block mt-2' onClick={() => openPopup({ currentPopup: 'UploadFile', info: { title: t("library.actions.uploadFile") } })}>
                            <div className='libraryMenu__menuBottomBtn'>
                                <FileUploadRoundedIcon />
                                <span className='ml-3'>{t("library.actions.uploadFile")}</span>
                            </div>
                        </ButtonBase>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(LibraryMenu));