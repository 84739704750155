import React, { useState } from 'react';
import { connect, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import { BlueButton } from '../../buttons/buttons';
import { closePopup } from "../../../actions/popup";
import { openNotification } from "../../../actions/notification";
import { leaveFeedback } from "../../../service/requests";
import { requestCommonData } from "../../../actions/commonData";
import { withTranslation } from 'react-i18next';
import validator from 'validator';

const mapDispatchToProps = (dispatch) => ({
    closePopup: () => {
        return dispatch(closePopup());
    },
    openNotification: (payload) => {
        return dispatch(openNotification(payload));
    },
});

const Feedback = (props) => {
    const dispatch = useDispatch();
    const { closePopup, openNotification, t } = props;

    const [data, setData] = useState({
        firstName: "",
        email: "",
        message: "",
        subject: ""
    })

    const [error, setError] = useState({
        firstName: false,
        email: false,
        message: false
    })


    const validate = () => {
        let nameError = "";
        let emailError = "";
        let messageError = "";

        if (!data.firstName || !data.firstName.match(/[a-zA-Z]+/g)) {
            nameError = "Name can not be blank";
        }

        if (!validator.isEmail(data.email)) {
            emailError = "Invalid email";
        }

        if (!data.message || !data.message.match(/[a-zA-Z]+/g)) {
            messageError = "Message can not be blank";
        }

        if (emailError || nameError || messageError) {
            setError({
                firstName: nameError,
                email: emailError,
                message: messageError,
            });
            return false;
        }
        return true;
    };


    const onCreateFolder = async () => {
        const isValid = validate();
        if (isValid) {

            const modifiedData = {
                ...data,
                name: data.firstName
              };
              delete modifiedData.firstName;

            try {
                await leaveFeedback(modifiedData);
                dispatch(requestCommonData());
                openNotification({ text: 'Your message has been sent', notificationType: "success" });
                closePopup();
            } catch (e) {
                console.log(e)
            }
        }
    }

    return (
        <div className='mt-7 w-full'>
            <TextField error={error.firstName} size='small' label={t("name")} placeholder={t("name")} className='w-full' value={data.name} onChange={(e) => { setData({...data, firstName: e.target.value}); setError({...error, firstName: false})}} variant="outlined" />
            <TextField error={error.email} size='small' label={t("email")} placeholder={t("email")} className='w-full mt-4' value={data.email} onChange={(e) => { setData({...data, email: e.target.value}); setError({...error, email: false}) }} variant="outlined" />
            <TextField error={error.subject} size='small' label={t("subject")} placeholder={t("subject")} className='w-full mt-4' value={data.subject} onChange={(e) =>  setData({...data, subject: e.target.value})} variant="outlined" />
            <TextField
                multiline
                rows={5}
                maxRows={5}
                error={error.message}
                size='small'
                label={t("message")}
                placeholder={t("message")}
                className='w-full mt-4'
                value={data.message}
                onChange={(e) => { setData({...data, message: e.target.value}); setError({...error, message: false}) }} 
                variant="outlined"
            />
            <div className='mt-7 w-min flex gap-3'>
                <BlueButton text={t("library.actions.send")} onClick={onCreateFolder} />
            </div>
        </div>
    )
}

export default connect(
    null,
    mapDispatchToProps
)(withTranslation()(Feedback));