// Popup
export const OPEN_POPUP = "OPEN_POPUP";
export const CLOSE_POPUP = "CLOSE_POPUP";

// Notification
export const OPEN_NOTIFICATION = "OPEN_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";

// Current User
export const CURRENT_USER = "CURRENT_USER";

// Schools
export const SET_SCHOOLS = "SET_SCHOOLS";
export const ADD_SCHOOL = "ADD_SCHOOL";
export const UPDATE_SCHOOL = "UPDATE_SCHOOL";
export const DELETE_SCHOOL = "DELETE_SCHOOL";

// // Tags
// export const SET_TAGS = "SET_TAGS";
// export const ADD_TAG = "ADD_TAG";
// export const UPDATE_TAG = "UPDATE_TAG";
// export const DELETE_TAG = "DELETE_TAG";

// // Selection Window
// export const OPEN_SELECTION_WINDOW = "OPEN_SELECTION_WINDOW";
// export const CLOSE_SELECTION_WINDOW = "CLOSE_SELECTION_WINDOW";

// Classes
// export const SET_CLASSES = "SET_CLASSES";
// export const ADD_CLASS = "ADD_CLASS";
// export const UPDATE_CLASS = "UPDATE_CLASS";
// export const DELETE_CLASS = "DELETE_CLASS";
// export const UPSERT_COLLECTION_TO_CLASS = "UPSERT_COLLECTION_TO_CLASS";

// // Search
export const SET_SEARCH_WORD = "SET_SEARCH_WORD";
export const CHECK_LAST_ELEM = "CHECK_LAST_ELEM";
export const SET_PAGE = "SET_PAGE";
export const SET_PAGE_NAME = "SET_PAGE_NAME";
export const SET_DATA_LOADER = "SET_DATA_LOADER";

// Words
export const SET_WORDS = "SET_WORDS";

// Speaker Languages
export const SET_SPEAKER_LANGS = "SET_SPEAKER_LANGS";


// Theme Mode
export const SET_THEME_MODE = "SET_MODE";

// Pages
export const SET_IS_NOT_FOUND = "SET_IS_NOT_FOUND";

// Languages
export const SET_LANGUAGES = "SET_LANGUAGES";


// FOLDERS
export const SET_FOLDERS = "SET_FOLDERS";
export const GET_FOLDERS = "GET_FOLDERS";
export const FAIL_FOLDERS = "FAIL_FOLDERS";

// TAGS
export const SET_TAGS = "SET_TAGS";
export const GET_TAGS = "GET_TAGS";
export const FAIL_TAGS = "FAIL_TAGS";
export const FILTER_TAGS = 'FILTER_TAGS';

export const SELECT_COMPOSER_OPUS = 'SELECT_COMPOSER_OPUS';
export const CLEAR_COMPOSER_OPUS = 'CLEAR_COMPOSER_OPUS';
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';
export const HOVER_COMPOSER_OPUS = 'HOVER_COMPOSER_OPUS';

// COMMON DATA 
export const SET_COMMON_DATA = 'SET_COMMON_DATA';
export const GET_COMMON_DATA = 'GET_COMMON_DATA';
export const FAIL_COMMON_DATA = 'FAIL_COMMON_DATA';

export const SET_SELECTED_PLAYLIST = 'SET_SELECTED_PLAYLIST';