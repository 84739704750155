import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import { SaveButton, CancelButton } from '../../buttons/buttons';
import { closePopup } from "../../../actions/popup";
import { openNotification } from "../../../actions/notification";
import CircularProgress from '@mui/material/CircularProgress';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { requestCommonData } from "../../../actions/commonData";
import { withTranslation } from 'react-i18next';

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => {
    return dispatch(closePopup());
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  }
});


const UpdateComment = (props) => {
  const dispatch = useDispatch();
  const { closePopup, openNotification, info, t } = props;
  const { type, onSave: update, data: itemData } = info;
  const [data, setData] = useState(itemData.comments);
  const [isLoading, setIsLoading] = useState(false);

  const onSave = async (event) => {
    event.preventDefault();
    
    update(itemData.id, data).then(closePopup).catch(console.log);
    try {
      await update(itemData.id, data);
      closePopup()
      dispatch(requestCommonData());
      openNotification({ text: 'Updated!', notificationType: "success" });
    } catch(e) {
      console.log(e)
    }
  }

  return (
    <div className='mt-7 w-full sm:w-96 relative'>
      <form onSubmit={onSave}>
        <TextField
          multiline
          rows={5}
          size='small'
          value={data}
          onChange={(e) => setData(e.target.value)}
          label={`${type.charAt(0).toUpperCase() + type.slice(1)}`}
          placeholder={`Enter ${type}`}
          className='w-full'
          variant="outlined" />
      <div className='mt-7 ml-auto w-min flex gap-3'>
        <SaveButton disabled={isLoading} text='OK' onClick={onSave} />
        <CancelButton text={t("library.actions.cancel")} onClick={() => closePopup()} />
      </div>
      {isLoading &&
        <div className='popupLoaderWrapper'><CircularProgress size={50} sx={{ color: '#2f5473' }} /></div>
      }
      </form>
    </div>
  )
}

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(UpdateComment));
