import {
    SET_SELECTED_PLAYLIST
 } from './index';
 
 
 export const selectPlaylist = (selectedPlaylist) => {
    return {
        type: SET_SELECTED_PLAYLIST,
        selectedPlaylist,
    }
};