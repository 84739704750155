import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ButtonBase } from '@mui/material';
import '../searchMenu/searchMenu.scss';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useNavigate } from "react-router-dom";

const mapStateToProps = (state => ({
    ...state.commonData,
}))


const ComposerMenu = (props) => {
    const { currentEtude, composers, composerId, opus } = props;
    const searchMenu = useRef(null);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [currentComposer, setCurrentComposer] = useState();
    const [currentOpus, setCurrentOpus] = useState();
    const navigate = useNavigate();


    useEffect(() => {
        if (window.innerWidth <= 640) {
            function adjustMenuHeight() {
                if (searchMenu.current) {
                    const windowHeight = window.innerHeight;
                    const menuHeight = windowHeight;

                    searchMenu.current.style.height = `${menuHeight}px`;
                }
            }
            window.addEventListener('resize', adjustMenuHeight);
            adjustMenuHeight();

            return () => {
                window.removeEventListener('resize', adjustMenuHeight);
            };
        }

    }, [window.innerWidth]);

    useEffect(() => {
        if (composers && composerId) {
            const composer = composers.find(composer => composer.composer_id == composerId);

            if(composer) {
                setCurrentComposer(composer);

                const foundOpus = composer?.opus?.find(op => op.opusNum === opus);

                setCurrentOpus(foundOpus);
            }
        }
    }, [composers, composerId]);


    const etudesMenuContent = () => {
        return (
            <>
                <div onClick={() => setIsMobileMenuOpen(false)} className={isMobileMenuOpen ? 'libraryMenu__overlay--active' : 'libraryMenu__overlay'}></div>
                <div className={`libraryMenu ${isMobileMenuOpen ? 'libraryMenu--open' : ''}`} ref={searchMenu}>
                    <Link to='/library' className='flex items-center mt-5'>
                        <ButtonBase className=''>
                            <div className='hamburger-iconWrapper'>
                                <ArrowBackIosNewRoundedIcon style={{ color: '#23425C' }} />
                            </div>
                        </ButtonBase>
                        <p className='font-semibold text-lg ml-5 text-white'>Go to my Library</p>
                    </Link>

                    {(currentComposer && currentEtude && currentOpus) &&
                        <>
                            <p className='text-2xl text-skyBlue mt-5 font-medium'>{currentComposer.composer_first_name} {currentComposer.composer_last_name}</p>

                            <div className='searchMenu__data'>
                                <div className='searchMenu__etudes'>
                                    <p className='uppercase text-white font-semibold text-sm mt-3'>Etudes</p>
                                    <div className='mt-5 flex flex-col ml-2 searchMenu__etudesItems'>
                                        {currentOpus.etudes.map(etude => {
                                            return (
                                                <div className={etude.etude_id == currentEtude.etude_id ? 'searchMenu__item--active' : 'searchMenu__item'} onClick={() => navigate(`/composers/${composerId}/${opus}/${etude.etude_id}`)}>{etude.etude_name}</div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div className='block sm:hidden z-10 cursor-pointer mt-auto mb-8 pt-5' onClick={() => setIsMobileMenuOpen(false)} >
                        <img src="/images/ico/closeMenu.svg" />
                    </div>
                </div>
            </>
        )
    }


    return (
        <>
            <div className='hidden sm:block libraryMenuWrapper'>
                <div className='libraryMenuWrapperFixed'>
                    <div className='hidden sm:flex h-full'>
                        {etudesMenuContent()}
                    </div>
                </div>
            </div>
            <div className="flex sm:hidden h-full">
                {etudesMenuContent()}
            </div>

            {!isMobileMenuOpen &&

                <div className='sm:hidden z-10 fixed bottom-10 right-6 cursor-pointer' onClick={() => setIsMobileMenuOpen(true)}>
                    <img src="/images/ico/menu.svg" />
                </div>
            }
        </>
    )
}

export default connect(mapStateToProps, null)(ComposerMenu);