import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from "@react-oauth/google";
// import {gapi} from "gapi-script";
import './googleAuth.scss';
import { withTranslation } from 'react-i18next';

// actions
import { openNotification } from "../../actions/notification";
import { currentUser } from "../../actions/currentUser";
import { closePopup } from "../../actions/popup";

// api
import { authGoogle, getMe } from "../../service/requests";

const mapDispatchToProps = (dispatch) => ({
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
  currentUser: (payload) => {
    return dispatch(currentUser(payload));
  },
  closePopup: () => {
    return dispatch(closePopup());
  },
});

const GoogleAuth = (props) => {
  const { openNotification, currentUser, setIsGoogleAuthLoading, closePopup, t } = props;

  const navigate = useNavigate();
  // const clientId = "119326919458-efacc3jlri69hh9erltn4cae3q3kh8mc.apps.googleusercontent.com";

  // useEffect(() => {
  //   gapi.load("client: auth2", () => {
  //     gapi.auth2.init({clientId: clientId});
  //   });
  // }, []);

  const responseGoogle = async (payload) => {
    const resp = await authGoogle(payload).catch(console.log);

    if(resp) {
      const { token } = resp.data;

      if(token)
        localStorage.setItem("accessToken", token);
        closePopup();

        try {
          const resUser = await getMe();
          if (resUser.data.account) {
            currentUser({user: resUser.data.account});
          }
        } catch (e) {
          console.log("e", e);
        }

    } else {
      setIsGoogleAuthLoading(false);
      openNotification({text: 'Something went wrong', notificationType: "error"});
    }
  }

  const login = useGoogleLogin({
    flow: 'auth-code',
    scope: 'email',
    onSuccess: async (codeResponse) => {
      setIsGoogleAuthLoading(true);
      responseGoogle(codeResponse);
    },
    onError: errorResponse => console.log(errorResponse),
  });


  return (
    <div className="w-54">
      <button className="googleBtn" onClick={() => login()}>
        <div className="bg-white p-1 rounded mr-3">
          <img src="./images/ico/google.svg" className="block mx-auto" />
        </div>
        {t("continueWithGoogle")}
      </button>
    </div>
  );
};

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(GoogleAuth));