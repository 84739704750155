import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from "react-redux";
import './library.scss';
import LibraryHeader from '../../components/libraryHeader/libraryHeader';
import LibraryMenuWrapper from '../../components/libraryMenuWrapper/libraryMenuWrapper';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import { openPopup } from '../../actions/popup';
import { openNotification } from '../../actions/notification';
import LibraryMobileView from './libraryMobileView';
import { useNavigate } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import ButtonBase from '@mui/material/ButtonBase';
import { withTranslation } from 'react-i18next';
import TableSortLabel from '@mui/material/TableSortLabel';

// table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ContextMenu from "../../components/contextMenu/contextMenu";

import {
  requestCommonData
} from "./../../actions/commonData";

import {
  selectPlaylist
} from './../../actions/library';

import {
  baseURL,
  updatePlaylistItem,
  movePlaylistItem,
  copyPlaylistItem,
  duplicatePlaylistItem,
  deletePlaylistItem,
  removeUpload
} from './../../service/requests';

// icons 
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';

const mapDispatchToProps = dispatch => ({
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  },
  selectPlaylist(selectedPlaylist) {
    return dispatch(selectPlaylist(selectedPlaylist));
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
}
})

const mapStateToProps = (state => ({
  ...state.commonData,
  ...state.currentUser,
  library: state.library
}))

const Library = (props) => {
  const dispatch = useDispatch();
  const { openPopup, loadData, folders, uploads, user, selectPlaylist, library, openNotification, t } = props;
  const { selectedPlaylist } = library;
  const [contextMenu, setContextMenu] = useState(null);
  const navigate = useNavigate();
  const [folderId, setFolderId] = useState(null);
  const [isUploadsOpen, setIsUploadsOpen] = useState(false);
  const [mobileActiveItem, setMobileActiveItem] = useState(null);
  const [allPlaylists, setAllPlaylists] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('');

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    let orderWay = '';
    
    if(order === 'desc') {
      orderWay = '';
    } else {
      orderWay = isAsc ? 'desc' : 'asc';
    }

    

    setOrder(orderWay);
    setOrderBy(property);
  };

  const setSelectedPlaylist = (pl) => {
    selectPlaylist(pl);
  }

  useEffect(() => {
    const allPlaylists = folders.reduce((accumulator, folder) => {
      accumulator.push(...folder.playlists);
      return accumulator;
    }, []);

    if (selectedPlaylist) {
      const filteredPlaylists = allPlaylists.filter(playlist => {
        return (
          playlist.playlist_id !== selectedPlaylist.playlist_id
        );
      });
      console.log('filteredPlaylists', filteredPlaylists)
      setAllPlaylists(filteredPlaylists);
    } else {
      setAllPlaylists(allPlaylists);
    }
  }, [folders, selectedPlaylist])

  useEffect(() => {
    if (allPlaylists.length && !selectedPlaylist && !isUploadsOpen) {
      selectPlaylist(allPlaylists[0]);
    }
  }, [allPlaylists])


  const updateRating = (item, rating) => {
    const payload = {
      id: item.id,
      rating
    };

    return updatePlaylistItem(payload).then(resp => {
      dispatch(requestCommonData());
      openNotification({ text: 'Updated!', notificationType: "success" });
    });
  }

  const openEtudes = (item) => {
    if (item.upload_id) {
      let currentUploadFile = uploads.find(x => x.upload_id === item.upload_id) 
      window.open(`${baseURL}/upload-files/${currentUploadFile.upload_path}`, '_blank', 'noreferrer')
    } else {
      navigate(`/etudes/${item.tag_id}/${item.etude_id}`);
    }
  };

  const moveToPlaylist = async (item, playlist_id) => {
    try {
      const res = await movePlaylistItem(item.id, playlist_id);
      dispatch(requestCommonData());
      openNotification({ text: 'Moved!', notificationType: "success" });
      console.log('res', res)
    } catch (e) {
      console.log(e)
    }
  }

  const copyToPlaylist = async (item, playlist_id) => {
    try {
      const res = await copyPlaylistItem(item.id, playlist_id);
      dispatch(requestCommonData());
      openNotification({ text: 'Copied!', notificationType: "success" });
      console.log('res', res)
    } catch (e) {
      console.log(e)
    }
  }


  const copyUploadToPlaylist = async (item, playlist_id) => {
    console.log(item, 'test');

    const findItemWithUploadId = (uploadId) => {
      let foundItem = null;

      folders.forEach(folder => {
        folder.playlists.forEach(playlist => {
          const item = playlist.items.find(item => item.upload_id === uploadId);
          if (item) {
            foundItem = item;
          }
        });
      });
    
      return foundItem;
    }

    const selectedItem = findItemWithUploadId(item.upload_id);
    console.log('selectedItem', selectedItem.id)
    console.log('item.upload_id', item.upload_id)

    try {
      // const res = await copyPlaylistItem(selectedItem.id, playlist_id);
      const res = await copyPlaylistItem(selectedItem.id, playlist_id);
      dispatch(requestCommonData());
      openNotification({ text: 'Copied!', notificationType: "success" });
    } catch (e) {
      console.log(e)
    }
  }

  const duplicateItem = async (item) => {
    try {
      const res = await duplicatePlaylistItem(item.id, item.n, item.playlist_id);
      dispatch(requestCommonData());
      openNotification({ text: 'Duplicated!', notificationType: "success" });
      console.log('res', res)
    } catch (e) {
      console.log(e)
    }
  }

  const deleteUploadItem = async (item) => {
    try {
      const res = await removeUpload({ id: item.upload_id, user_id: user.id });
      dispatch(requestCommonData());
      openNotification({ text: 'Duplicated!', notificationType: "success" });
      console.log('res', res)
    } catch (e) {
      console.log(e)
    }
  }

  const deleteItem = async (item) => {
    try {
      const res = await deletePlaylistItem(item.id, item.n, item.playlist_id);
      dispatch(requestCommonData());
      openNotification({ text: 'Item was deleted', notificationType: "success" });
      console.log('res', res)
    } catch (e) {
      console.log(e)
    }
  }

  const downloadPdf = (item) => {
    if (item.upload_id) {
      let currentUploadItem = uploads.find(x => x.upload_id === item.upload_id);
      window.open(`${baseURL}/upload-files/${currentUploadItem.upload_path}`, '_blank', 'noreferrer')
    } else {
      console.log('preview', item.preview)
      window.open(`${baseURL}/content/etude-sheetmusic/${item.preview}.pdf`, '_blank', 'noreferrer')
    }
  }

  const playlistOptions = [
    { label: t("library.actions.view"), action: openEtudes },
    {
      label: t("library.actions.moveToPlaylist"),
      subitems: allPlaylists.map(playlist => {
        return {
          label: playlist.playlist_name,
          action: (item) => moveToPlaylist(item, playlist.playlist_id),
        };
      })
    },
    {
      label: t("library.actions.copyToPlaylist"),
      subitems: allPlaylists.map(playlist => {
        return {
          label: playlist.playlist_name,
          action: (item) => copyToPlaylist(item, playlist.playlist_id),
        };
      })
    },
    { label: t("library.actions.duplicate"), action: duplicateItem },
    { label: t("library.actions.delete"), action: (item) => openPopup({ currentPopup: 'Confirmation', info: { title: `${t("library.actions.delete")}?`, confirmationText: t("library.message.areYouShureYouWantToDeleteThisItem"), onConfirm: () => deleteItem(item) } })},
    { label: t("library.actions.downloadPdf"), action: downloadPdf },
  ];

  const uploadOptions = [
    { label: t("library.actions.view"), action: openEtudes },
    { label: t("library.actions.edit"), action: (item) => openPopup({ currentPopup: 'UploadFile', info: { title: t("library.actions.editFile"), item, isEditMode: true } }) },
    {
      label: t("library.actions.copyToPlaylist"),
      subitems: allPlaylists.map(playlist => {
        return {
          label: playlist.playlist_name,
          action: (item) => copyUploadToPlaylist(item, playlist.playlist_id),
        };
      })
    },
    { label: t("library.actions.delete"), action: (item) => openPopup({ currentPopup: 'Confirmation', info: { title: `${t("library.actions.delete")}?`, confirmationText: `Are you sure you want to delete this ${item.upload_name} item?`, onConfirm: () => deleteUploadItem(item) } })},
    { label: t("library.actions.downloadPdf"), action: downloadPdf },
  ];

  const handleContextMenu = (event, item) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          top: event.clientY - 6,
          left: event.clientX + 2,
          item
        }
        :
        null
    );
  };

  const getDifficulty = (number) => {
    const elements = [];
    for (let i = 0; i < number; i++) {
      elements.push(<div className='search__difficulty' key={i}></div>);
    }
    return elements;
  }

  const onEditComment = async (id, comments) => {
    const payload = {
      id,
      comments
    };

    updatePlaylistItem(payload).then(resp => {
      dispatch(requestCommonData());
    });
  }

  const handleDragStart = (e, item) => {
    setDraggedItem(item)
  };

  const showMyContent = () => {
    return (
      <div className='libraryTable rounded text-blue bg-transparent sm:bg-white'>

        <div className='libraryTable__generalInfo'>
          <div className='flex flex-col'>
            <div className='libraryTable__titleWrapper'>
              <div className='text-xl font-semibold'>{t("myUploads")}</div>
            </div>
            <div className='text-sm font-medium'>{uploads.length} {uploads.length > 1 ? t("library.table.items") : t("library.table.item")}</div>
          </div>
          <div className={mobileActiveItem ? 'libraryTable__navButtons' : 'libraryTable__navButtons opacity-40'}>
            <img onClick={() => downloadPdf(mobileActiveItem)} className='ml-4 w-6 cursor-pointer' src="./images/ico/download.svg" alt="download" />
            <img onClick={() => openPopup({ currentPopup: 'UploadFile', info: { title: t("library.actions.editFile"), item: mobileActiveItem, isEditMode: true } })} className='ml-5 w-6 cursor-pointer' src="./images/ico/edit.svg" alt="edit" />
            <img onClick={() => deleteUploadItem(mobileActiveItem)} className='ml-4 w-7 cursor-pointer' src="./images/ico/delete.svg" alt="delete" />
          </div>
        </div>

        {uploads.length > 0 &&
          <>
            {!!contextMenu?.top && (
              <ContextMenu
                anchorPosition={{ top: contextMenu.top, left: contextMenu.left }}
                options={uploadOptions}
                handleClose={() => setContextMenu(null)}
                index={contextMenu?.item}
              />
            )}
            <div className='library__itemsWrapper'>
              <LibraryMobileView
                copyUploadToPlaylist={copyUploadToPlaylist}
                moveToPlaylist={moveToPlaylist}
                copyToPlaylist={copyToPlaylist}
                allPlaylists={allPlaylists}
                setMobileActiveItem={setMobileActiveItem}
                type="uploads"
                items={uploads} />
            </div>

            <div className='library__table'>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("library.table.type")}</TableCell>
                      <TableCell>{t("library.table.name")}</TableCell>
                      <TableCell>{t("library.table.composer")}</TableCell>
                      <TableCell>{t("library.table.volume")}</TableCell>
                      <TableCell>{t("library.table.difficulty")}</TableCell>
                      <TableCell>{t("library.table.rating")}</TableCell>
                      <TableCell>{t("library.table.preview")}</TableCell>
                      <TableCell>{t("library.table.comment")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {uploads.map((item, index) => (
                      <TableRow
                        draggable
                        onDragStart={(e) => handleDragStart(e, item)}
                        onClick={() => openEtudes(item)}
                        key={item.id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        onContextMenu={(event) => handleContextMenu(event, item)}
                      >
                        <TableCell component="th" scope="row">
                          <img src="./images/ico/personalUpload.svg" style={{padding: '4px 0'}} alt="personal upload" />
                        </TableCell>
                        <TableCell >{item.upload_name}</TableCell>
                        <TableCell >{item.composer_first_name} {item.composer_last_name}</TableCell>
                        <TableCell >{item.volume}</TableCell>
                        <TableCell >
                          <div className='flex gap-1'>
                            {getDifficulty(item.difficulty)}
                          </div>
                        </TableCell>
                        <TableCell >
                        </TableCell>
                        <TableCell >
                        </TableCell>
                        <TableCell>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

            </div>
          </>}
      </div>
    )
  }


  const sortedItems = order ? [...selectedPlaylist?.items].sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    } else if(order === 'desc') {
      return a[orderBy] > b[orderBy] ? -1 : 1;
    }
  }) : selectedPlaylist?.items;

  console.log('sortedItems', sortedItems, selectedPlaylist?.items)

  return (
    <div className='flex flex-col sm:flex-row'>
      <LibraryMenuWrapper copyUploadToPlaylist={copyUploadToPlaylist} copyToPlaylist={copyToPlaylist} draggedItem={draggedItem} setSelectedPlaylist={setSelectedPlaylist} setIsUploadsOpen={setIsUploadsOpen} setFolderId={setFolderId} />
      <div className='content flex-1 py-1 sm:py-10 px-5 sm:px-8'>
        <div className='hidden sm:block'>
          <LibraryHeader />
        </div>

        {isUploadsOpen && showMyContent()}

        {selectedPlaylist &&
          <div className='libraryTable rounded text-blue bg-transparent sm:bg-white'>

            <div className='libraryTable__generalInfo'>
              <div className='flex flex-col'>
                <div className='libraryTable__titleWrapper' onClick={() => openPopup({ currentPopup: 'Rename', info: { title: t("library.actions.renamePlaylist"), type: 'playlist', folderId, playlistId: selectedPlaylist.playlist_id, data: selectedPlaylist.playlist_name } })}>
                  <div className='text-xl font-semibold'>{selectedPlaylist.playlist_name}</div>
                  <img className='ml-3 w-5 cursor-pointer' src="./images/ico/edit.svg" alt="edit" />
                </div>
                <div className='text-sm font-medium'>{selectedPlaylist.items.length} {selectedPlaylist.items.length > 1 ? t("library.table.items") : t("library.table.item")}</div>
              </div>
              <div className={mobileActiveItem ? 'libraryTable__navButtons' : 'libraryTable__navButtons opacity-40 pointer-events-none'}>
                <img onClick={() => duplicateItem(mobileActiveItem)} className='w-7 cursor-pointer' src="./images/ico/duplicate.svg" alt="duplicate" />
                <img onClick={() => downloadPdf(mobileActiveItem)} className='ml-4 w-6 cursor-pointer' src="./images/ico/download.svg" alt="download" />
                <img onClick={() => openPopup({ currentPopup: 'Confirmation', info: { title: `${t("library.actions.delete")}?`, confirmationText: t("library.message.areYouShureYouWantToDeleteThisItem"), onConfirm: () => deleteItem(mobileActiveItem) } })} className='ml-4 w-7 cursor-pointer' src="./images/ico/delete.svg" alt="delete" />
              </div>
            </div>

            <div></div>

            {!!contextMenu?.top && (
              <ContextMenu
                anchorPosition={{ top: contextMenu.top, left: contextMenu.left }}
                options={playlistOptions}
                handleClose={() => setContextMenu(null)}
                index={contextMenu?.item}
              />
            )}
            <div className='library__itemsWrapper'>
              <LibraryMobileView
                copyUploadToPlaylist={copyUploadToPlaylist}
                moveToPlaylist={moveToPlaylist}
                copyToPlaylist={copyToPlaylist}
                allPlaylists={allPlaylists}
                setMobileActiveItem={setMobileActiveItem}
                type="playlist"
                items={selectedPlaylist.items} />
            </div>

            <div className='library__table'>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortLabel
                          hideSortIcon={!order}
                          direction={orderBy === 'type' ? order : 'asc'}
                          onClick={() => handleSort('type')}>
                            Type
                        </TableSortLabel>
                        </TableCell>
                      <TableCell>
                        <TableSortLabel
                          hideSortIcon={!order}
                          direction={orderBy === 'name' ? order : 'asc'}
                          onClick={() => handleSort('name')}>
                            Name
                        </TableSortLabel></TableCell>
                      <TableCell>
                        <TableSortLabel
                          hideSortIcon={!order}
                          direction={orderBy === 'composer' ? order : 'asc'}
                          onClick={() => handleSort('composer')}>
                            Composer
                        </TableSortLabel></TableCell>
                      <TableCell>
                        <TableSortLabel
                          hideSortIcon={!order}
                          direction={orderBy === 'volume' ? order : 'asc'}
                          onClick={() => handleSort('volume')}>
                            Volume
                        </TableSortLabel></TableCell>
                      <TableCell>
                        <TableSortLabel
                          hideSortIcon={!order}
                          direction={orderBy === 'difficulty' ? order : 'asc'}
                          onClick={() => handleSort('difficulty')}>
                            Difficulty
                        </TableSortLabel></TableCell>
                      <TableCell>
                        <TableSortLabel
                          hideSortIcon={!order}
                          direction={orderBy === 'rating' ? order : 'asc'}
                          onClick={() => handleSort('rating')}>
                            Rating
                        </TableSortLabel></TableCell>
                      <TableCell>
                        Preview</TableCell>
                      <TableCell>Comment</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedItems.map((item, index) => (
                      <TableRow
                        draggable
                        onDragStart={(e) => handleDragStart(e, item)}
                        key={item.id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        onContextMenu={(event) => handleContextMenu(event, item)}
                      >
                        <TableCell onClick={() => openEtudes(item)} component="th" scope="row">
                          {item.upload_id
                            ? <img src="./images/ico/personalUpload.svg" style={{padding: '4px 0'}} alt="personal upload" />
                            : <img src="./images/ico/fileType.svg" alt="personal upload" />
                          }
                        </TableCell>
                        <TableCell onClick={() => openEtudes(item)} >{item.name}</TableCell>
                        <TableCell onClick={() => openEtudes(item)} >{item.composer}</TableCell>
                        <TableCell onClick={() => openEtudes(item)} >{item.volume}</TableCell>
                        <TableCell onClick={() => openEtudes(item)} >
                          <div  className='flex gap-1'>
                            {getDifficulty(item.difficulty)}
                          </div>
                        </TableCell>
                        <TableCell onClick={() => openEtudes(item)} >
                          <StyledRating
                            name="simple-controlled"
                            value={item.rating}
                            max={4}
                            icon={<StarRateRoundedIcon />}
                            emptyIcon={<StarBorderRoundedIcon />}
                            onChange={(event, newValue) => {
                              updateRating(item, newValue);
                            }}
                          />
                        </TableCell>
                        <TableCell  onClick={() => openEtudes(item)}>

                          {!item.upload_id &&
                            <Tooltip
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: '#ffffff',
                                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
                                  },
                                },
                              }}
                              TransitionComponent={Fade}
                              placement="left"
                              title={
                                <img className='library__preview-open' src={`${baseURL}/content/etude-thumbnails/${item.preview}.png`} alt="note" />
                              }
                            >
                              <img className='library__preview' src={`${baseURL}/content/etude-thumbnails/${item.preview}.png`} alt="note" />
                            </Tooltip>
                          }
                        </TableCell>
                        <TableCell>
                          <div
                            className={item.comments ? 'libraryTable__comment border border-lightBlue transition hover:border-blue p-2 rounded-xl' : 'border text-lightGray border-lightBlue transition hover:border-blue p-2 rounded-xl'}
                            onClick={() => openPopup({ currentPopup: 'UpdateComment', info: { title: t("library.actions.editComment"), type: 'comment', data: item, onSave: onEditComment } })}>
                              <div className='libraryTable__commentItem'>{item.comments ? item.comments : t("library.actions.noCommentYet")}</div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {!selectedPlaylist.items.length && <div className='flex flex-col items-center justify-center w-fit mx-auto p-5'>
                <div className="">
                  <object type="image/svg+xml" data="./images/ico/noData.svg" className="w-20" />
                </div>
                <p className='text-sm mt-2'>{t("library.noItemsInPlaylist")}</p>
              </div>}
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Library));

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#6BC3E8',
  },
  '& .MuiRating-iconHover': {
    color: '#6BC3E8',
  },
});

