import {
    SET_SEARCH_WORD,
    CHECK_LAST_ELEM,
    SET_PAGE,
    SET_PAGE_NAME,
    SET_DATA_LOADER,
    FILTER_TAGS,
    SELECT_COMPOSER_OPUS,
    CLEAR_COMPOSER_OPUS,
    CLEAR_ALL_FILTERS,
    HOVER_COMPOSER_OPUS
 } from './index';
 
 
 export const setSearchWord = (searchWord) => {
    return {
        type: SET_SEARCH_WORD,
        searchWord,
    }
}

export const selectTag = (selectedTag) => {
    return {
        type: CHECK_LAST_ELEM,
        selectedTag,
    }
}

export const hoverComposerOpus = (opus) => {
    return {
        type: HOVER_COMPOSER_OPUS,
        hoveredOpus: opus,
    }
}

export const selectComposerOpus = (opus) => {
    return {
        type: SELECT_COMPOSER_OPUS,
        selectedOpus: opus,
    }
}

export const clearComposerOpus = () => {
    return {
        type: CLEAR_COMPOSER_OPUS,
        selectedOpus: null,
    }
}

export const clearAllFilters = () => {
    return {
        type: CLEAR_ALL_FILTERS,
        selectedOpus: null,
        filteredTags: []
    }
}

export const setFilteredTags = (filteredTags) => {
    return {
        type: FILTER_TAGS,
        filteredTags,
    }
}

export const setPageName = (payload) => {
    const { pageName } = payload;
    return {
        type: SET_PAGE_NAME,
        pageName,
    }
}

export const setPage = (payload) => {
    const { page } = payload;
    return {
        type: SET_PAGE,
        page,
    }
}

export const setDataLoader = (payload) => {
    const { isDataLoading } = payload;
    return {
        type: SET_DATA_LOADER,
        isDataLoading,
    }
}