import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import { closeNotification } from '../../actions/notification';


const mapStateToProps = state => ({
    ...state.notifications
});

const mapDispatchToProps = dispatch => ({
    closeNotification: () => {
        return dispatch(closeNotification());
    },
})

const Notification = (props) => {

    const {
        closeNotification,
        text,
        notificationType,
        showNotification
    } = props;

    // type of notifications :
    // error
    // warning
    // info
    // success

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        closeNotification()
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseRoundedIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            <Snackbar
                open={showNotification}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleClose} severity={notificationType}>
                    {text}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Notification);