import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import GoogleAuth from "../../googleAuth/googleAuth";
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ButtonBase } from '@mui/material';
import { openPopup, closePopup } from '../../../actions/popup';
import { currentUser } from '../../../actions/currentUser';
import { openNotification } from '../../../actions/notification';
import validator from 'validator';
import { signup } from '../../../service/requests';
import { MainBlueButton } from '../../buttons/buttons';
import CircularProgress from '@mui/material/CircularProgress';
import { withTranslation } from 'react-i18next';
import './signUp.scss'


const mapDispatchToProps = dispatch => ({
    openPopup: (payload) => {
        return dispatch(openPopup(payload));
    },
    currentUser: (payload) => {
        return dispatch(currentUser(payload));
    },
    openNotification: (payload) => {
        return dispatch(openNotification(payload));
    },
    closePopup: () => {
        return dispatch(closePopup());
      },
})

const SignUp = (props) => {

    const { openPopup, currentUser, closePopup, t } = props;
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const [user, setUser] = useState({
        name: "",
        lastName: "",
        email: "",
        password: "",
    });

    const [error, setError] = useState({
        name: "",
        lastName: "",
        email: "",
        password: "",
    });

    const handleChange = (e) => {
        setUser({
            ...user,
            [e.target.id]: e.target.value,
        });

        setError({
            ...error,
            [e.target.id]: false,
        });
    }


    // validation
    const validate = () => {
        let nameError = "";
        let lastNameError = "";
        let emailError = "";
        let passwordError = "";

        if (!user.name || !user.name.match(/[a-zA-Z]+/g)) {
            nameError = "Name can not be blank";
        }

        if (!user.lastName || !user.lastName.match(/[a-zA-Z]+/g)) {
            lastNameError = "Last name can not be blank";
        }

        if (!validator.isEmail(user.email)) {
            emailError = "Invalid email";
        }

        if (user.password.length < 5) {
            passwordError = "Password is too short";
        }

        if (user.password != passwordRepeat) {
            passwordError = "Passwords do not match";
        }

        if (emailError || nameError || lastNameError || passwordError) {
            setError({
                name: nameError,
                lastName: lastNameError,
                email: emailError,
                password: passwordError,
            });
            return false;
        }
        return true;
    };

    const signUp = async (e) => {
        e.preventDefault();
        const isValid = validate();

        if (isValid) {
            setIsLoading(true);
            setError({
                name: "",
                lastName: "",
                email: "",
                password: "",
            });

            try {

                let updatedUser = { ...user, name: user.name.trim(), lastName: user.lastName.trim() };
                const res = await signup(updatedUser);

                if (res.data.ok) {
                    localStorage.setItem("accessToken", res.data.token);
                    currentUser({ user: res.data.user });
                }

                setIsLoading(false);
            } catch (e) {
                console.log(e);
                setIsLoading(false);
                openNotification({ text: "Something went wrong, please check your info once again", notificationType: "error" });
            }
        }
    };

    return (
        <div className='mt-7 relative authWrapper'>
            <TextField error={error.name} label={t("firstName")} id="name" value={user.name} onChange={(e) => { handleChange(e) }} className='w-full' variant="outlined" />
            <TextField error={error.lastName} label={t("lastName")} id="lastName" value={user.lastName} onChange={(e) => { handleChange(e) }} className='w-full mt-3' variant="outlined" />
            <TextField error={error.email} label={t("email")} id="email" value={user.email} onChange={(e) => { handleChange(e) }} className='w-full mt-3' variant="outlined" />
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" className='w-full m-0 mt-3'>
                <InputLabel htmlFor="outlined-adornment-password">{t("password")}</InputLabel>
                <OutlinedInput
                    id="password"
                    value={user.password}
                    onChange={(e) => { handleChange(e) }}
                    error={error.password}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Password"
                />
            </FormControl>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" className='w-full m-0 mt-3'>
                <InputLabel htmlFor="outlined-adornment-password">{t("confirmPassword")}</InputLabel>
                <OutlinedInput
                    error={error.password}
                    value={passwordRepeat}
                    onChange={(e) => { setPasswordRepeat(e.target.value); setError({ ...error, password: false }) }}
                    type={showPassword ? 'text' : 'password'}
                    onKeyDown={(event) => event.key === 'Enter' && signUp(event)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label={t("confirmPassword")}
                />
                {error.password && (
                    <FormHelperText error id="error_password" sx={{ margin: 0 }}>
                        {error.password}
                    </FormHelperText>
                )}
            </FormControl>

            <div className='w-full mt-3'>
                <MainBlueButton onClick={signUp} text={t("signUp")} isLoading={isLoading} />
            </div>

            <div className='px-5 w-full text-center font-medium mt-3 text-dark'>{t("or")}</div>

            <div className='mt-3'>
                <GoogleAuth setIsGoogleAuthLoading={(isLoading) => setIsLoading(isLoading)} />
            </div>

            <div className='text-lightBlue hover:text-blue mt-7 text-center' onClick={() => openPopup({ currentPopup: 'Login', info: { title: 'Login' } })}>{t("alreadyHaveAnAccount")}</div>

            {isLoading &&
                <div className='popupLoaderWrapper'><CircularProgress size={50} sx={{ color: '#2f5473' }} /></div>
            }
        </div>
    )
}

export default connect(
    null,
    mapDispatchToProps
  )(withTranslation()(SignUp));