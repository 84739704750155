import {
    SET_FOLDERS
 } from '../actions';
 
 const folders = (state = {
    folders: [],
 }, action) => {
 
    const {
       folders,
    } = action;
 
    switch (action.type) {
       case SET_FOLDERS: {
            return {
            ...state,
            folders: folders,
            }
       }
       
       default:
          return state;
    }
}

export default folders;

