import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ButtonBase from '@mui/material/ButtonBase';
import { AccentButton, CancelButton, PlainButton } from '../../buttons/buttons';
import { closePopup } from "../../../actions/popup";
import { openNotification } from "../../../actions/notification";
import { withTranslation } from 'react-i18next';

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => {
    return dispatch(closePopup());
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
});

const Confirmation = (props) => {
  const {
    closePopup,
    info,
    t
  } = props;

  const [isLoading, setIsLoading] = useState(props.info.isLoading);

  const onConfirm = () => {
    setIsLoading(true);
    closePopup();
    info.onConfirm();
  };

  return (
    <section className="relative">
      <div className="mt-5 text-lg text-left text-darkGray">{info.confirmationText}</div>
      <div className="ml-auto w-min mt-5 flex gap-5 mt-10">
        <PlainButton onClick={closePopup} text={t("library.actions.cancel")} />
        <CancelButton text={t("library.actions.delete")} onClick={() => onConfirm()} />
      </div>
    </section>
  );
};

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(Confirmation));
