import {
    SET_TAGS
 } from '../actions';
 
 const tags = (state = {
    folders: [],
 }, action) => {
 
    const {
       folders,
    } = action;
 
    switch (action.type) {
       case SET_TAGS: {
            return {
            ...state,
            folders: folders,
            }
       }
       
       default:
          return state;
    }
}

export default tags;

