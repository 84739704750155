import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import './search.scss'
import { connect } from "react-redux";

import { clearComposerOpus, setFilteredTags, setSearchWord } from './../../actions/search';

const mapDispatchToProps = dispatch => ({
    setFilteredTags(tags) {
        return dispatch(setFilteredTags(tags))
    },

    clearComposerOpus() {
        return dispatch(clearComposerOpus())
    },

    setSearchWord(searchWord) {
        return dispatch(setSearchWord(searchWord))
    }
})

const mapStateToProps = (state => ({
    commonData: state.commonData,
    search: state.search
}))

const Search = (props) => {
    const { search: { filteredTags, selectedOpus, searchWord }, setFilteredTags, clearComposerOpus, setSearchWord } = props;

    const filterTags = (tagToFilter) => {
        let newTags = filteredTags.filter(tag => tag.tag_id !== tagToFilter.tag_id);

        setFilteredTags(newTags);
    }

    return (
        <>
            <div className='search__inputWrapper'>
                <img src="/images/ico/search.svg" alt="search icon" />
                <input type="text" value={searchWord} onChange={(event) => setSearchWord(event.target.value)} placeholder="Search..." />
            </div>

            <div className='search__tags flex-wrap'>
                {filteredTags.map(tag => {
                    return <ButtonBase onClick={() => filterTags(tag)} className='rounded-full'>
                        <div className='search__tag'>
                            {tag.tag_name}
                            <span className='search__tagDeleteBtn'><DeleteRoundedIcon fontSize="16px" style={{ color: '#ffffff' }} /></span>
                        </div>
                    </ButtonBase>
                })}
            </div>

            <div className='search__tags flex-wrap'>
                {selectedOpus && <ButtonBase onClick={() => clearComposerOpus()} className='rounded-full'>
                        <div className='search__tag'>
                            {selectedOpus.opusNum}
                            <span className='search__tagDeleteBtn'><DeleteRoundedIcon fontSize="16px" style={{ color: '#ffffff' }} /></span>
                        </div>
                    </ButtonBase>}
            </div>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);