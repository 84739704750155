import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from "react-redux";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AccentButton, SaveButton } from '../../buttons/buttons';
import { closePopup } from "../../../actions/popup";
import { openNotification } from "../../../actions/notification";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { requestCommonData } from "../../../actions/commonData";
import { uploadFile, updatePlaylist, addToPlaylist, updateUploadedFile } from '../../../service/requests';
import Chip from '@mui/material/Chip';
import { withTranslation } from 'react-i18next';
import '../../buttons/buttons.scss'
import './uploadFile.scss';


const mapStateToProps = (state => ({
    ...state.commonData,
    ...state.currentUser
}))


const mapDispatchToProps = (dispatch) => ({
    closePopup: () => {
        return dispatch(closePopup());
    },
    openNotification: (payload) => {
        return dispatch(openNotification(payload));
    },
});


const UploadFile = (props) => {
    const dispatch = useDispatch();
    const { closePopup, openNotification, folders, composers, tags, user, info, t } = props;
    const inputEl = useRef(null);
    const keywordsInputRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const item = info.item;
    const [selectedFolder, setSelectedFolder] = useState('');
    const [selectedPlaylist, setSelectedPlaylist] = useState('');
    const [difficulty, setDifficulty] = useState(item?.difficulty || '');
    const [isCreateComposerdMode, setIsCreateComposerMode] = useState(false);
    const [composerFirstName, setComposerFirstName] = useState('');
    const [composerLastName, setComposerLastName] = useState('');
    const [fileName, setFileName] = useState(item?.upload_name || '');
    const [selectedComposer, setSelectedComposer] = useState(composers.find(x => x.composer_id === item?.composer.composer_id) || '');
    const [selectedKeywords, setSelectedKeywords] = useState('');
    const [volume, setVolume] = useState(item?.volume || '');
    const [file, setFile] = useState()
    const [selectedFileName, setSelectedFileName] = useState();
    const [selectedTags, setSelectedTags] = useState(tags.filter(tag => item?.tagIds.includes(tag.tag_id)) || []);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [error, setError] = useState({
        folder: false,
        playlist: false,
        fileName: false,
        composer: false,
        volume: false,
        difficulty: false,
        keywords: false,
        file: false,
    })

    const onButtonClick = () => {
        inputEl.current.click();
    };

    const handleChangeDifficulty = (event) => {
        const value = event.target.value;
        if (!isNaN(value) && value <= 5) {
            setDifficulty(value);
        }
        setError({ ...error, difficulty: false })
    };

    const handleselectedFile = event => {

        let fileSize = event.target.files[0].size;
        if (fileSize > 5e6) {
            setError({ ...error, file: true })
            return;
        }

        setError({ ...error, file: false })
        setFile(event.target.files[0]);
        setSelectedFileName(event.target.files[0].name);
    };

    // validation
    const validate = () => {
        let folderError = "";
        let playlistError = "";
        let fileNameError = "";
        let composerError = "";
        let volumeError = "";
        let difficultyError = "";
        let keywordsError = "";
        let fileError = "";

        // if (!selectedFolder) {
        //     folderError = true;
        // }

        // if (!selectedPlaylist) {
        //     playlistError = true;
        // }

        // if (!fileName) {
        //     fileNameError = true;
        // }

        if (isCreateComposerdMode) {
            if (!composerFirstName || !composerLastName) {
                composerError = true;
            }
        } else {
            if (!selectedComposer) {
                composerError = true;
            }
        }

        // if (!volume) {
        //     volumeError = true;
        // }

        if (!difficulty) {
            difficultyError = true;
        }

        // if (!selectedKeywords) {
        //     keywordsError = true;
        // }

        if (!file) {
            fileError = true;
        }

        if (folderError || playlistError || fileNameError || composerError || volumeError || difficultyError || keywordsError || fileError) {
            setError({
                folder: folderError,
                playlist: playlistError,
                fileName: fileNameError,
                composer: composerError,
                volume: volumeError,
                difficulty: difficultyError,
                keywords: keywordsError,
                file: fileError
            });
            return false;
        }
        return true;
    };

    const onUploadFile = async (e) => {
        e.preventDefault();
        const isValid = validate();
        setLoading(true);

        if (isValid) {
            setUploadInProgress(true);
            const formData = new FormData();
            formData.append('file', file);
            const tagsIds = selectedTags.map(x => x.tag_id);

            const headers = {
                'Mydata': JSON.stringify({
                    "id": user.id,
                    "name": fileName,
                    "play_id": selectedPlaylist.playlist_id,
                    "composer_first_name": isCreateComposerdMode ? composerFirstName : selectedComposer.composer_first_name,
                    "composer_last_name": isCreateComposerdMode ? composerLastName : selectedComposer.composer_last_name,
                    "volume": volume,
                    "difficulty": difficulty,
                    "keywords": null,
                    "tagIds": tagsIds,
                    "composer_id": isCreateComposerdMode ? null : selectedComposer.composer_id
                }),
                'Content-Type': 'multipart/form-data'
            };

            try {
                const res = await uploadFile(formData, headers);
                const payload = {
                    playlist_id: selectedPlaylist.playlist_id,
                    seq: selectedPlaylist?.items?.length,
                    upload_id: res.data.id,
                    user_id: user.id
                };
                
                try {
                    await addToPlaylist(payload);
                } catch(e) {
                    console.log(e);
                }

                dispatch(requestCommonData());
                closePopup();
                openNotification({ text: 'The file has been added', notificationType: "success" });
            }
            catch (e) {
                console.log(e);
            }
        }

        setLoading(false);
    }

    const onTagDelete = (tag) => {
        let updatedTags = selectedTags.filter(x => x.tag_id != tag.tag_id)
        setSelectedTags(updatedTags)
    }

    const onUpdate = async () => {
        console.log('onUpdate info?.upload_id', item?.upload_id)

        setLoading(true);

        const tagsIds = selectedTags.map(x => x.tag_id);

        const data = {
            cunstomName: fileName,
            composer_first_name: isCreateComposerdMode ? composerFirstName : selectedComposer.composer_first_name,
            composer_last_name: isCreateComposerdMode ? composerLastName : selectedComposer.composer_last_name,
            composer_id: isCreateComposerdMode ? null : selectedComposer.composer_id,
            difficulty: difficulty,
            keywords: "",
            tagIds: tagsIds,
            tag_id: null,
            volume: volume,
        }
        try {
            // on file edit updatePlaylist
            await updateUploadedFile(item?.upload_id, data);
            dispatch(requestCommonData());
            closePopup();
            openNotification({ text: 'Updated', notificationType: "success" });
        } catch (e) {
            console.log(e)
        }

        setLoading(false);
    }

    const handleAutoCompleteChange = (newValue) => {
        if (newValue !== null) {
            if (!selectedTags.some(tag => tag.tag_id === newValue.tag_id)) {
                setSelectedTags([...selectedTags, newValue]);
                setError({ ...error, keywords: false });
            }

            if (keywordsInputRef.current) {
                setTimeout(() => {
                    keywordsInputRef.current.focus();
                }, 0);
            }
        }
    };

    return (
        <div className='mt-7 w-full sm:w-96 uploadFile'>
            {!info?.isEditMode &&
                <div className='flex flex-col sm:flex-row mt-4 gap-5'>
                    <FormControl size='small' className='flex-1'>
                        <InputLabel id="demo-simple-select-label">{t("library.label.selectFolder")}</InputLabel>
                        <Select
                            error={error.folder}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedFolder}
                            label={t("library.label.selectFolder")}
                            onChange={(e) => { setSelectedFolder(e.target.value); setError({ ...error, folder: false }) }}
                        >
                            {folders.map(folder => {
                                return (
                                    <MenuItem key={folder.folder_id} value={folder}>{folder.folder_name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <FormControl size='small' className='flex-1'>
                        <InputLabel id="demo-simple-select-label2">{t("library.label.selectPlaylist")}</InputLabel>
                        <Select
                            error={error.playlist}
                            labelId="demo-simple-select-label2"
                            id="demo-simple-select"
                            value={selectedPlaylist}
                            label={t("library.label.selectPlaylist")}
                            onChange={(e) => { setSelectedPlaylist(e.target.value); setError({ ...error, playlist: false }) }}
                        >
                            {selectedFolder && selectedFolder.playlists.length && selectedFolder.playlists.map(playlist => {
                                return (
                                    <MenuItem key={playlist.playlist_id} value={playlist}>{playlist.playlist_name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>
            }
            <TextField error={error.fileName} size='small' label={t("library.table.name")} value={fileName} onChange={(e) => { setFileName(e.target.value); setError({ ...error, fileName: false }) }} placeholder={t("library.placeholder.enterName")} className={info?.isEditMode ? 'w-full mt-0' : 'w-full mt-4'} variant="outlined" />
            
            {isCreateComposerdMode ?
                <div className='flex flex-col sm:flex-row gap-0 sm:gap-5'>
                    <TextField error={error.composer} size='small' label={t("library.label.composerFirstName")} value={composerFirstName} onChange={(e) => { setComposerFirstName(e.target.value); setError({ ...error, composer: false }) }} placeholder={t("library.placeholder.enterComposerFirstName")} className='w-full mt-4' variant="outlined" />
                    <TextField error={error.composer} size='small' label={t("library.label.composerLastName")} value={composerLastName} onChange={(e) => { setComposerLastName(e.target.value); setError({ ...error, composer: false }) }} placeholder={t("library.placeholder.enterComposerLastName")} className='w-full mt-4' variant="outlined" />
                </div>
                :

                <Autocomplete
                    options={composers}
                    disablePortal
                    getOptionLabel={(option) => option && `${option.composer_first_name} ${option.composer_last_name}`}
                    value={selectedComposer}
                    onChange={(event, newValue) => {
                        setSelectedComposer(newValue);
                        setError({ ...error, composer: false })
                    }}
                    id="composers"
                    size="small"
                    className='w-full mt-4'
                    noOptionsText={
                        <div className='w-max mx-auto'>
                            <AccentButton text="Create a composer?" onClick={() => setIsCreateComposerMode(true)} />

                        </div>
                    }
                    renderInput={(params) => <TextField {...params} error={error.composer} label={t("library.label.composerName")} />}
                />

            }

            <div className='flex flex-col sm:flex-row mt-4 gap-5'>
                <TextField size='small' error={error.volume} label={t("library.table.volume")} value={volume} onChange={(e) => { setVolume(e.target.value); setError({ ...error, volume: false }) }} placeholder={t("library.placeholder.enterVolume")} className='flex-1' variant="outlined" />
                <TextField
                    value={difficulty}
                    onChange={handleChangeDifficulty}
                    type="number"
                    error={error.difficulty}
                    size='small'
                    className='flex-1'
                    InputProps={{
                        inputProps: {
                            max: 5, min: 1
                        }
                    }}
                    label={t("library.table.difficulty")}
                />
            </div>

            <Autocomplete
                options={tags}
                value={null}
                blurOnSelect={true}
                onChange={(event, newValue) => handleAutoCompleteChange(newValue)}
                getOptionLabel={(option) => option && `${option.tag_name}`}
                id="composers"
                size="small"
                className='w-full mt-4'
                renderInput={(params) => <TextField inputRef={keywordsInputRef} {...params} error={error.keywords} label={t("library.label.keywords")} />}
            />

            <div className='mt-3 flex gap-2 flex-wrap'>
                {selectedTags.map(tag => {
                    return (
                        <Chip
                            key={tag.tag_id}
                            label={tag.tag_name}
                            onDelete={() => onTagDelete(tag)}
                        />
                    )
                })}
            </div>

            {!info?.isEditMode &&
                !file && <p className={error.file ? 'my-4 text-center text-redError' : 'my-4 text-center text-dark'}>{t("library.message.pleaseUploadYourFile")}</p>}
            {selectedFileName && <div className='my-3 bg-greenTransparent p-2 rounded text-green flex justify-between'>{selectedFileName} <span onClick={() => { setSelectedFileName() }}><CloseRoundedIcon /></span></div>}


            <div className='flex gap-3 w-min ml-auto'>
                {info?.isEditMode

                    ? <SaveButton disabled={loading} onClick={onUpdate} text='Update' />
                    : <>
                        <div className='relative overflow-hidden'>
                            <AccentButton text='Browse' onClick={() => onButtonClick()} />
                            <input id="file-upload" type="file" ref={inputEl} onChange={(e) => handleselectedFile(e)} accept="application/pdf" className='absolute pointer-events-none top-0 left-0 opacity-0 z-10' />
                        </div>
                        <SaveButton disabled={loading} onClick={onUploadFile} text='Upload' />
                    </>
                }
            </div>
        </div>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(UploadFile));
  