import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import ButtonBase from '@mui/material/ButtonBase';
import './uploadImage.scss';
import { SaveButton, AccentButton } from '../../../components/buttons/buttons';
import Slider from '@mui/material/Slider';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';
import { updateUserInfo } from '../../../service/requests';
import CircularProgress from '@mui/material/CircularProgress';
import Resizer from "react-image-file-resizer";

// import { addUserPhoto, getUser } from '../../../service/requests';


// img 
// import illustration from '../../../img/uploadImageIllustration.svg';

// actions 
import { currentUser } from '../../../actions/currentUser';
import { closePopup } from '../../../actions/popup';
import { openNotification } from '../../../actions/notification';

const mapStateToProps = (state) => ({
   ...state.currentUser,
});

const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   },
   currentUser: (payload) => {
      return dispatch(currentUser(payload));
   },
   openNotification: (payload) => {
      return dispatch(openNotification(payload));
   }
})

const UploadImage = (props) => {

   const {
      info,
      closePopup,
      openNotification,
      user
   } = props;

   const [image, setImage] = useState(user && user.avatar);
   const [zoom, setZoom] = useState(1)
   const [isLoading, setIsLoading] = useState(false);

   const [crop, setCrop] = React.useState({ x: 0, y: 0 })
   const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null)

   const onImageChange = async (e) => {
      try {
         const file = e.target.files[0];
         const image = await resizeFile(file);
         setImage(URL.createObjectURL(image));
      } catch (error) {
         console.log(error);
      }
   }

   const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels)
   }, [])

   const uploadImage = async (croppedImage) => {
      try {
         let res = await updateUserInfo({
            id: user.id,
            profile_image: croppedImage
         });
         if (res.data) {
            openNotification({ text: 'Your photo updated successfully', notificationType: 'success' });
            closePopup()

            window.location.reload();
         }

         setIsLoading(false);
      } catch (e) {
         console.error(e)
         setIsLoading(false);
      }
   }

   const resizeFile = (file) =>
      new Promise((resolve) => {
         Resizer.imageFileResizer(
            file,
            400,
            400,
            "JPEG",
            100,
            0,
            (uri) => {
               resolve(uri);
            },
            "file"
         );
      });

   const showCroppedImage = useCallback(async () => {
      try {
         setIsLoading(true)
         const croppedImage = await getCroppedImg(
            image,
            croppedAreaPixels
         )
         uploadImage(croppedImage);
      } catch (e) {
         console.error(e)
      }

   }, [croppedAreaPixels])


   const onCancel = () => {
      if (!image) {
         closePopup()
      }
      setCroppedAreaPixels(null);
      setImage(null);
   }

   return (
      <section className="text-dark realtive">
         <div className='text-sm sm:text-base mt-1 font-medium'>Please upload a photo that clearly shows your face.</div>
         <div className="mt-7">
            <div className="uploadImage__innerWrapper">
               {!image &&
                  <div className=''>
                     <div className="p-3 w-max mx-auto">
                        <object type="image/svg+xml" data="./images/ico/uploadImage.svg" className="w-20" />
                     </div>
                     <div className="w-36 text-dark dark:text-white text-center mx-auto my-5 text-sm">Drop your file here or <span className="text-purple dark:text-orange">Browse</span></div>
                  </div>
               }

               {/* {isLoading &&  
               <div className="w-40 my-5 mx-auto">
                  <div className="w-36 text-center mx-auto mt-2 text-sm text-purple">Uploading...</div>
                </div>
               } */}

               <input
                  type="file"
                  name="file"
                  onChange={onImageChange}
                  accept="image/x-png,image/gif,image/jpeg"
                  className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
               />

               {image &&
                  <>
                     <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={1 / 1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        cropShape='round'
                        showGrid={false}
                     />

                     <div className="uploadImage__zoomSlider">
                        <Slider
                           value={zoom}
                           min={1}
                           max={3}
                           step={0.1}
                           aria-labelledby="Zoom"
                           onChange={(e, zoom) => setZoom(zoom)}
                           classes={{ root: 'slider' }}
                        />
                     </div>
                  </>
               }
            </div>

            <div className="mt-10 flex items-center w-max mx-auto">
               <div className='w-max overflow-hidden relative'>
                  <input
                     type="file"
                     name="file"
                     onChange={onImageChange}
                     // accept="image/x-png,image/gif,image/jpeg,image/jpg,image/png"
                     className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
                  />
                  <div className={isLoading || !image ? "btn btn--disabled" : "btn accentBtn"}>Upload new</div>
               </div>
               <SaveButton className="ml-3" disabled={isLoading} isLoading={isLoading} text="Save" onClick={showCroppedImage} />
               <object type="image/svg+xml" onClick={() => setImage()} className={isLoading || !image ? 'ml-3 opacity-20 transition' : 'ml-3 hover:opacity-80 transition cursor-pointer'} data="./images/ico/delete.svg" alt="delete photo"></object>
            </div>
         </div>

         {isLoading &&
            <div className='popupLoaderWrapper'><CircularProgress size={50} sx={{ color: '#2f5473' }} /></div>
         }
      </section>
   )
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(UploadImage);