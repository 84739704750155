import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import { SaveButton, CancelButton } from '../../buttons/buttons';
import { closePopup } from "../../../actions/popup";
import { openNotification } from "../../../actions/notification";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { createPlaylist } from '../../../service/requests';
import { requestCommonData } from './../../../actions/commonData';
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state => ({
    ...state.commonData
}))

const mapDispatchToProps = (dispatch) => ({
    closePopup: () => {
        return dispatch(closePopup());
    },
    openNotification: (payload) => {
        return dispatch(openNotification(payload));
    },
});

const CreatePlaylist = (props) => {
    const dispatch = useDispatch();
    const { closePopup, openNotification, folders, info, t } = props;
    const [selectedFolder, setSelectedFolder] = useState('');
    const [playlistName, setPlaylistName] = useState('');
    const [error, setError] = useState({
        folder: false,
        playlistName: false
    });

    useEffect(()=> {
        if(info.isEditMode) {
            setSelectedFolder(folders.find(folder => folder.folder_id === info.data.folder_id))
        }
    }, [])
    

    const validate = () => {
        let folderError = "";
        let playlistNameError = "";

        if (!selectedFolder) {
            folderError = true;
        }

        if (!playlistName) {
            playlistNameError = true;
        }

        if (folderError || playlistNameError) {
            setError({
                folder: folderError,
                playlistName: playlistNameError,
            });
            return false;
        }
        return true;
    };

    const onCreatePlaylist = async () => {
        const isValid = validate();

        if (isValid) {
            try {
                await createPlaylist(selectedFolder.folder_id, { playlist_name: playlistName });
                dispatch(requestCommonData());
                openNotification({ text: 'Playlist was added!', notificationType: "success" });
                closePopup()
            } catch (e) {
                console.log(e)
            }
        }
    }

    return (
        <div className='mt-7 w-full sm:w-96'>
            <form onSubmit={(event) => { console.log(event); event.preventDefault(); onCreatePlaylist()}}>
            <FormControl size='small' className='flex-1 w-full'>
                <InputLabel id="create-playlist-folder-select">{t("library.label.selectFolder")}</InputLabel>
                <Select
                    labelId="create-playlist-folder-select"
                    id="demo-simple-select"
                    value={selectedFolder}
                    label="Select Folder"
                    disabled={info.isEditMode}
                    onChange={(event) => { setSelectedFolder(event.target.value); setError({ ...error, folder: false }) }}
                >
                    {folders.map(folder => {
                        return (
                            <MenuItem value={folder}>{folder.folder_name}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>

            <TextField autoFocus={info.isEditMode} size='small' label={t("library.label.playlistName")}  value={playlistName} onChange={(e) => { setPlaylistName(e.target.value); setError({ ...error, playlistName: false }) }} placeholder={t("library.placeholder.enterPlaylistName")} className='w-full mt-4' variant="outlined" />
            <div className='mt-7 ml-auto w-min flex gap-3'>
                <SaveButton text='OK' onClick={onCreatePlaylist} />
                <CancelButton text={t("library.actions.cancel")} onClick={() => closePopup()} />
                <button hidden='true' type="submit"></button>
            </div>
            </form>
        </div>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(CreatePlaylist));