import {
    OPEN_NOTIFICATION,
    CLOSE_NOTIFICATION
 } from './index';
 
 
 export const openNotification = (payload) => {
    const { notificationType, text } = payload;
    return {
        type: OPEN_NOTIFICATION,
        notificationType, 
        text
        
    }
 }
 
 export const closeNotification = () => {
    return {
        type: CLOSE_NOTIFICATION
    }
 }