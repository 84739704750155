import {
    SET_COMMON_DATA,
    GET_COMMON_DATA,
    FAIL_COMMON_DATA
 } from '../actions';
 
 const commonData = (state = {
    etudes: [],
    media: [],
    uploads: [],
    tags: [],
    tagHasMedia: [],
    etudeHasTags: [],
    folders: [],
    composers: []
 }, action) => {
 
    const {
       etudes = state.etudes,
       media = state.media,
       uploads = state.uploads,
       tags = state.tags,
       tagHasMedia = state.tagHasMedia,
       etudeHasTags = state.etudeHasTags,
       folders = state.folders,
       composers = state.composers,
    } = action;
 
    switch (action.type) {
       case SET_COMMON_DATA: {
            return {
                ...state,
                etudes,
                media,
                uploads,
                tags,
                tagHasMedia,
                etudeHasTags,
                folders,
                composers,
                loading: false,
                error: false
            }
       }

       case GET_COMMON_DATA: {
            return {
                ...state,
                loading: true,
                error: false
            }
        }

        case FAIL_COMMON_DATA: {
            return {
                ...state,
                loading: false,
                error: true
            }
        }
       
       default:
          return state;
    }
}

export default commonData;

