import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Confirmation from '../popups/confirmation/confirmation';
import Login from '../popups/login/login';
import EditProfile from '../popups/editProfile/editProfile';
import UploadImage from '../popups/uploadImage/uploadImage';
import SignUp from '../popups/signUp/signUp';
import UploadFile from '../popups/uploadFile/uploadFile';
import CreateFolder from '../popups/createFolder/createFolder';
import Rename from '../popups/rename/rename';
import CreatePlaylist from '../popups/createPlaylist/createPlaylist';
import ForgotPassword from '../popups/forgotPassword/forgotPassword';
import UpdateComment from './../popups/updateComment/updateComment';
import PasswordConfirmation from './../popups/passwordConfirmation/passwordConfirmation';
import Feedback from './../popups/feedback/feedback';

import {
   closePopup,
} from '../../actions/popup';

const mapStateToProps = state => ({
   ...state.popups
});

const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   },
})

const Popup = (props) => {

   const theme = localStorage.getItem("theme");


   const handleClose = () => {
      props.closePopup();
   }

   const map = {
      'Confirmation': Confirmation,
      'Login': Login,
      'SignUp': SignUp,
      'EditProfile': EditProfile,
      'UploadImage': UploadImage,
      'UploadFile': UploadFile,
      'CreateFolder': CreateFolder,
      'Rename': Rename,
      'CreatePlaylist': CreatePlaylist,
      'ForgotPassword': ForgotPassword,
      'UpdateComment': UpdateComment,
      'PasswordConfirmation': PasswordConfirmation,
      'Feedback': Feedback
   }


   const CurrentPopup = map[props.currentPopup];
      return (
         <Dialog
            open={props.showPopup}
            onClose={handleClose}
            maxWidth="sm"
            className="dialog"
            transitionDuration={100}
         >
            <DialogContent className="cursor-pointer dialog--blurred dialog customBorder bg-whiteTransparent dark:bg-darkModeDark py-2 sm:py-3 px-2 sm:px-5">
               <div className='flex justify-between'>
                  {/* {props.currentPopup === 'Login' && props.info.title && */}
                     <div className='font-bold text-xl sm:text-2xl text-dark'>{props.info.title}</div>
                  {/* } */}
                  <div className='rounded border border-lightGray transition hover:border-gray w-7 h-7 ml-auto flex items-center justify-center' onClick={handleClose}>
                     <img src='./images/ico/close.svg' alt="" className='w-min block' />
                  </div>
               </div>
               {CurrentPopup &&
                  <CurrentPopup info={props.info}/>
               }
            </DialogContent>
         </Dialog>
      )
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(Popup);